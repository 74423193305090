import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'LNumberedListItemProgress',
    props: {
        step: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['numbered-list-item-progress']]: true
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['numbered-list-item-progress-content'])
                }, [
                    _ctx.step ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['numbered-list-item-progress-text'])
                    }, _toDisplayString(_ctx.step), 3)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'NumberedListItemProgress'
                ]
            ]);
        };
    }
});
