import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { CategoryType } from '@leon-hub/api-sdk';
import { usePromotionsStore } from 'web/src/modules/promotions/store';
import { useNavigationItemsStore } from 'web/src/modules/core/store';
export default function usePromotionsRoute() {
    const route = useRoute();
    const promotionsStore = usePromotionsStore();
    const { promotionCategoriesNavigationItems } = storeToRefs(useNavigationItemsStore());
    const categoryId = computed(()=>route.params.categoryId ?? '');
    // eslint-disable-next-line max-len
    const archivedCategory = promotionsStore.categoryIdByCategoryType(CategoryType.ARCHIVED);
    const isArchivedCategory = computed(()=>categoryId.value === archivedCategory.value);
    const navigationItem = computed(()=>{
        let item = promotionCategoriesNavigationItems.value.find((navItem)=>navItem.id === categoryId.value) ?? null;
        if (!item) for (const navItem of promotionCategoriesNavigationItems.value)// eslint-disable-next-line @typescript-eslint/no-loop-func
        navItem.children?.forEach((navSubItem)=>{
            if (navSubItem.id === categoryId.value) item = navSubItem;
        });
        return item;
    });
    const sendYM = ()=>{
        promotionsStore.promotionsSendYM({
            categoryName: navigationItem.value?.caption
        });
    };
    return {
        categoryId,
        isArchivedCategory,
        navigationItem,
        sendYM
    };
}
