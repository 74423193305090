import { IconName, IconSize } from '@leon-hub/icons';
import { PromotionItemIds, PromotionItemType } from 'web/src/modules/promotions/types';
import isActiveCategory from './isActiveCategory';
import isActiveSubCategory from './isActiveSubCategory';
import mapBadges from './mapBadges';
import resolveCategoryLink from './resolveCategoryLink';
import resolveSubCategoryLink from './resolveSubCategoryLink';
export default function getMenu(promotionCategoriesNavigationItems, router, currentRouteName, actionUrl, categoryId, participationNumber) {
    return promotionCategoriesNavigationItems.map((category)=>{
        const L1Key = `level1-spoiler-${category.id}`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        const canOpen = category.id !== PromotionItemIds.BONUS_WALLET && category.type !== PromotionItemType.PROMOTION && !!category.children?.length;
        return {
            key: L1Key,
            id: category.id,
            location: resolveCategoryLink(category, router),
            name: category.caption || '',
            canOpen,
            active: isActiveCategory(category, currentRouteName, categoryId),
            menu: category.children?.map((subcategory)=>{
                const L2Key = `level2-spoiler-${subcategory.id}`;
                return {
                    key: L2Key,
                    id: subcategory.id,
                    location: resolveSubCategoryLink(subcategory, router),
                    name: subcategory.caption || '',
                    active: isActiveSubCategory(subcategory, actionUrl, categoryId),
                    suffix: subcategory.props && subcategory.props.isHot ? {
                        size: IconSize.SIZE_16,
                        name: IconName.FIRE
                    } : void 0,
                    menu: []
                };
            }) ?? [],
            badge: mapBadges(category, participationNumber)
        };
    });
}
