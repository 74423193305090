import { defineComponent as _defineComponent } from 'vue';
import { normalizeProps as _normalizeProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps } from "vue";
import SPromotionListItem from './SPromotionListItem/SPromotionListItem.vue';
import LPromotionsListItem from './LPromotionListItem/LPromotionsListItem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionsListItem',
    props: {
        promotionId: {},
        isArchived: {
            type: Boolean
        },
        isPromotionsLikeEnabled: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        return (_ctx, _cache)=>(_openBlock(), _createBlock(LPromotionsListItem, _normalizeProps(_mergeProps({
                key: 1
            }, props)), null, 16));
    }
});
