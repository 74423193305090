import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "terms-steps"
};
const _hoisted_2 = {
    class: "terms-steps__accordion-item__header"
};
const _hoisted_3 = {
    class: "terms-steps__title"
};
const _hoisted_4 = {
    class: "terms-steps__progress"
};
import { VIcon } from '@components/v-icon';
import VList from 'web/src/components/List/VList/VList.vue';
import { ListType } from 'web/src/components/List/enums';
import VTermsStepProgress from '../VTermsStepProgress/VTermsStepProgress.vue';
import { useVTermsSteps } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VTermsSteps',
    props: {
        items: {
            default: ()=>[]
        },
        showReadMoreButton: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { iconCheckProps, iconMoreProps, listItemControl } = useVTermsSteps(props);
        function emitClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(VList, {
                    class: "terms-steps__list",
                    "list-type": _unref(ListType).GROUP
                }, {
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index)=>(_openBlock(), _createBlock(_resolveDynamicComponent(_unref(listItemControl)(index)), {
                                    id: index.toString(),
                                    key: index,
                                    class: _normalizeClass([
                                        "terms-steps__accordion-item",
                                        {
                                            'terms-steps__accordion-item--finished': item.satisfied
                                        }
                                    ])
                                }, {
                                    inner: _withCtx(()=>[
                                            _createElementVNode("div", _hoisted_2, [
                                                _createElementVNode("div", {
                                                    class: _normalizeClass([
                                                        "terms-steps__icon-wrapper",
                                                        {
                                                            'terms-steps__icon-wrapper--checked': item.satisfied
                                                        }
                                                    ])
                                                }, [
                                                    item.satisfied ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                                                        key: 0,
                                                        ref_for: true
                                                    }, _unref(iconCheckProps), {
                                                        class: "terms-steps__icon"
                                                    }), null, 16)) : _createCommentVNode("", true)
                                                ], 2),
                                                _createElementVNode("span", _hoisted_3, _toDisplayString(item.name), 1)
                                            ])
                                        ]),
                                    content: _withCtx(()=>[
                                            _createElementVNode("div", _hoisted_4, [
                                                _createVNode(VTermsStepProgress, {
                                                    "progress-bar-value": item.progressBarValue ?? void 0,
                                                    "value-type": item.valueType ?? void 0,
                                                    "current-value": item.currentValue ?? void 0,
                                                    "required-value": item.requiredValue ?? void 0
                                                }, null, 8, [
                                                    "progress-bar-value",
                                                    "value-type",
                                                    "current-value",
                                                    "required-value"
                                                ])
                                            ])
                                        ]),
                                    _: 2
                                }, 1032, [
                                    "id",
                                    "class"
                                ]))), 128))
                        ]),
                    _: 1
                }, 8, [
                    "list-type"
                ]),
                _ctx.showReadMoreButton ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "terms-steps__read-more",
                    onClick: emitClick
                }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_READ_MORE')) + " ", 1),
                    _createVNode(_unref(VIcon), _mergeProps(_unref(iconMoreProps), {
                        class: "terms-steps__read-more-icon"
                    }), null, 16)
                ])) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'VTermsSteps'
                ]
            ]);
        };
    }
});
