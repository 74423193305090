import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { AlertIconName } from '@leon-hub/icons';
import VJumbotron from 'web/src/components/Jumbotron/VJumbotron/VJumbotron.vue';
import { getImageOrIcon } from 'web/src/modules/icons';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionsListEmpty',
    props: {
        title: {},
        description: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VJumbotron, {
                heading: _ctx.title,
                text: _ctx.description,
                image: _unref(getImageOrIcon)({
                    alertIcon: _unref(AlertIconName).Binoculars
                }).image
            }, {
                footer: _withCtx(()=>[
                        _ctx.$slots.button ? _renderSlot(_ctx.$slots, "button", {
                            key: 0
                        }) : _createCommentVNode("", true)
                    ]),
                _: 3
            }, 8, [
                "heading",
                "text",
                "image"
            ])), [
                [
                    _directive_auto_id,
                    'PromotionsListEmpty'
                ]
            ]);
        };
    }
});
