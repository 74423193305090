import { toRef, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { AccessDeniedRemoteApiExceptionCode, FeedbackChannel, Rating } from '@leon-hub/api-sdk';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { isString } from '@leon-hub/guards';
import { Timer } from '@leon-hub/utils';
import RouteName from '@leon-hub/routing-config-names';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { usePromotionsStore } from 'web/src/modules/promotions/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { ModalSelector } from 'web/src/modules/core/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useSupportFeedbackStore } from 'web/src/modules/support/submodules/feedback/store';
import { PromotionCustomerRatingMethodType } from 'web/src/modules/promotions/types';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
export default function usePromotionRate() {
    const { $translate } = useI18n();
    const { showDialog, closeDialog } = useDialogs();
    const router = useRouter();
    const scgStore = useSiteConfigStore();
    const isPromotionsLikeEnabled = toRef(scgStore, 'isPromotionsLikeEnabled');
    const promotionsLikeFeedbackCountdown = toRef(scgStore, 'promotionsLikeFeedbackCountdown');
    const feedbackFormMaxChars = toRef(scgStore, 'feedbackFormMaxChars');
    const customerDataStore = useCustomerDataStore();
    const haveValidEmailValue = toRef(customerDataStore, 'haveValidEmailValue');
    const customerEmail = toRef(customerDataStore, 'email');
    const promotionsStore = usePromotionsStore();
    const currentPromotion = toRef(promotionsStore, 'promotion');
    const promotionLikes = toRef(promotionsStore, 'promotionLikes');
    const isRateButtonEnabled = toRef(promotionsStore, 'isRateButtonEnabled');
    const { convertToBaseError } = useErrorsConverter();
    const supportFeedbackStore = useSupportFeedbackStore();
    const { isLoggedIn } = useIsLoggedIn();
    const isRateFeedBackModal = ref(false);
    const isLikePressed = ref(false);
    const isDislikePressed = ref(false);
    const isRateFeedBackVisible = ref(false);
    const feedbackCountdown = ref(0);
    const submitting = ref(false);
    const isApiErrorEmailMessage = ref('');
    const savedErrorDuplicateEmail = ref('');
    let updateRateFeedbackTimerId = 0;
    const modalSelector = ModalSelector.BODY;
    function setRateButtonPressed() {
        if (currentPromotion.value?.customerRating === Rating.POSITIVE) isLikePressed.value = true;
        if (currentPromotion.value?.customerRating === Rating.NEGATIVE) isDislikePressed.value = true;
    }
    function resetRateButton() {
        isLikePressed.value = false;
        isDislikePressed.value = false;
        promotionsStore.setInitialLikes(0);
        stopRateFeedbackCountdown();
    }
    async function setRating(rating) {
        const id = currentPromotion.value?.id;
        isString(id);
        await promotionsStore.promotionRate({
            id,
            rating
        });
    }
    function startRateFeedbackCountdown() {
        isRateFeedBackVisible.value = true;
        updateRateFeedbackTimerId = Timer.setInterval(()=>{
            feedbackCountdown.value -= 1;
            if (feedbackCountdown.value <= 0) stopRateFeedbackCountdown();
        }, 1000);
    }
    function stopRateFeedbackCountdown() {
        isRateFeedBackVisible.value = false;
        feedbackCountdown.value = promotionsLikeFeedbackCountdown.value;
        Timer.clearInterval(updateRateFeedbackTimerId);
    }
    function onCloseRateFeedback() {
        isRateFeedBackModal.value = false;
    }
    async function handleLike() {
        if (!isLoggedIn.value) {
            router.push({
                name: RouteName.LOGIN
            });
            return;
        }
        if (!isRateButtonEnabled.value) return;
        if (isDislikePressed.value) {
            stopRateFeedbackCountdown();
            promotionsStore.setLikes(PromotionCustomerRatingMethodType.INCREASE);
            isDislikePressed.value = false;
            isLikePressed.value = !isLikePressed.value;
            await setRating(Rating.POSITIVE);
            startRateFeedbackCountdown();
        } else {
            const rating = isLikePressed.value ? null : Rating.POSITIVE;
            const method = isLikePressed.value ? PromotionCustomerRatingMethodType.DECREASE : PromotionCustomerRatingMethodType.INCREASE;
            promotionsStore.setLikes(method);
            if (isLikePressed.value) stopRateFeedbackCountdown();
            else startRateFeedbackCountdown();
            isLikePressed.value = !isLikePressed.value;
            await setRating(rating);
        }
    }
    async function handleDislike() {
        if (!isLoggedIn.value) {
            router.push({
                name: RouteName.LOGIN
            });
            return;
        }
        if (!isRateButtonEnabled.value) return;
        if (isLikePressed.value) {
            stopRateFeedbackCountdown();
            promotionsStore.setLikes(PromotionCustomerRatingMethodType.DECREASE);
            isLikePressed.value = false;
            isDislikePressed.value = !isDislikePressed.value;
            await setRating(Rating.NEGATIVE);
            startRateFeedbackCountdown();
        } else {
            const rating = isDislikePressed.value ? null : Rating.NEGATIVE;
            if (isDislikePressed.value) stopRateFeedbackCountdown();
            else startRateFeedbackCountdown();
            isDislikePressed.value = !isDislikePressed.value;
            await setRating(rating);
        }
    }
    function feedbackRate() {
        if (isLoggedIn.value) isRateFeedBackModal.value = true;
        else {
            isLikePressed.value = false;
            isDislikePressed.value = false;
            router.push({
                name: RouteName.LOGIN
            });
        }
        stopRateFeedbackCountdown();
    }
    async function onRateFeedbackSubmit(data) {
        try {
            submitting.value = true;
            isApiErrorEmailMessage.value = '';
            savedErrorDuplicateEmail.value = '';
            const userEmail = haveValidEmailValue.value ? customerEmail.value : data.email;
            const payload = {
                body: data.comment,
                channel: FeedbackChannel.PROMOACTION,
                actionId: String(currentPromotion.value?.actionId),
                lbType: currentPromotion.value?.promotionType,
                rating: isLikePressed.value ? Rating.POSITIVE : Rating.NEGATIVE,
                email: userEmail
            };
            await supportFeedbackStore.sendUserResponse(payload);
            "1";
            showDialog({
                presetName: PresetName.ALERT_SUCCESS,
                options: {
                    confirmMessage: $translate('WEB2_THANK_YOU_FOR_FEEDBACK').value,
                    title: $translate('WEB2_FEEDBACK_SENT').value,
                    dataTestId: 'promo-feedback-success'
                }
            });
            isRateFeedBackModal.value = false;
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError && error.code.equals('EMAIL_ALREADY_EXISTS')) {
                isApiErrorEmailMessage.value = error.message;
                savedErrorDuplicateEmail.value = data.email;
                submitting.value = false;
                return;
            }
            isRateFeedBackModal.value = false;
            const { subscribe, id } = showDialog({
                presetName: PresetName.ALERT_WARNING,
                options: {
                    title: $translate('JS_CAPTION_ATTENTION').value,
                    confirmMessage: $translate('WEB2_USER_FEEDBACK_IS_NOT_SENT').value,
                    width: ModalWidth.SMALL,
                    dataTestId: 'promo-feedback-is-not-sent'
                }
            });
            subscribe({
                [DialogAction.MODAL_CLOSE]: ()=>{
                    closeDialog(id);
                }
            });
        }
        submitting.value = false;
    }
    function updateLikesState() {
        resetRateButton();
        setRateButtonPressed();
        if (currentPromotion.value?.likes) promotionsStore.setInitialLikes(currentPromotion.value.likes || 0);
    }
    function beforeMount() {
        feedbackCountdown.value = promotionsLikeFeedbackCountdown.value;
        useBusSafeSubscribe(BusEvent.API_ERROR, (param)=>{
            let { error } = param;
            const originalError = convertToBaseError(error);
            if (originalError.code.equals(AccessDeniedRemoteApiExceptionCode.ACCESS_DENIED) && !isLoggedIn.value) isRateFeedBackModal.value = false;
        });
        updateLikesState();
    }
    watch(currentPromotion, updateLikesState);
    return {
        isPromotionsLikeEnabled,
        isRateFeedBackVisible,
        isLikePressed,
        isDislikePressed,
        isRateButtonEnabled,
        isRateFeedBackModal,
        modalSelector,
        promotionLikes,
        feedbackCountdown,
        feedbackFormMaxChars,
        submitting,
        haveValidEmailValue,
        isApiErrorEmailMessage,
        savedErrorDuplicateEmail,
        beforeMount,
        handleLike,
        handleDislike,
        feedbackRate,
        onRateFeedbackSubmit,
        onCloseRateFeedback
    };
}
