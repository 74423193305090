import { IconName, IconSize } from '@leon-hub/icons';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
export default function useVTermsSteps(props) {
    const iconCheckProps = {
        name: IconName.CHECK_FILLED,
        size: IconSize.SIZE_20
    };
    const iconMoreProps = {
        name: IconName.OPEN_IN_NEW,
        size: IconSize.SIZE_16
    };
    function listItemControl(index) {
        if (props.items) {
            const { currentValue, requiredValue } = props.items[index];
            const isAccordion = null !== currentValue && null !== requiredValue;
            if (isAccordion) return VListItemAccordion;
        }
        return VListItem;
    }
    return {
        iconCheckProps,
        iconMoreProps,
        listItemControl
    };
}
