import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
const _hoisted_2 = {
    key: 1,
    class: "loader-masked"
};
import { computed, onMounted, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { Tag } from '@leon-hub/tags';
import RouteName from '@leon-hub/routing-config-names';
import VHowToHintBlock from 'web/src/components/HowToHintBlock/VHowToHintBlock/VHowToHintBlock.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import PromotionDetailsHowToReceiveBonus from 'web/src/modules/promotions/components/PromotionDetailsHowToReceiveBonus';
import PromotionDetailsDescription from 'web/src/modules/promotions/components/PromotionDetailsDescription/PromotionDetailsDescription.vue';
import { usePromotionsStore } from 'web/src/modules/promotions/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusWalletRoutePage',
    setup (__props) {
        const promotionsStore = usePromotionsStore();
        const router = useRouter();
        const bonusWallet = toRef(promotionsStore, 'bonusWallet');
        const isReady = computed(()=>bonusWallet.value?.actionUrl !== void 0);
        const titleTag = computed(()=>Tag.H1);
        function onBonusWalletButtonClick() {
            if (!bonusWallet.value) return;
            if (bonusWallet.value.linkedCategoryId) router.push({
                name: RouteName.PROMOTIONS,
                params: {
                    categoryId: bonusWallet.value.linkedCategoryId
                }
            });
        }
        onMounted(async ()=>{
            await promotionsStore.getBonusWalletPromotionAction();
            if (!bonusWallet.value) router.push({
                name: RouteName.PROMOTIONS
            });
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['component-wrapper'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['promotion promotion--bonus-wallet'])
                }, [
                    isReady.value && bonusWallet.value ? (_openBlock(), _createElementBlock("article", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['promotion__article'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['promotion__image-wrapper'])
                        }, [
                            _createElementVNode("img", {
                                class: _normalizeClass(_ctx.$style['promotion__image']),
                                src: bonusWallet.value.bannerUrl ?? void 0,
                                alt: bonusWallet.value.shortDescription ?? void 0
                            }, null, 10, _hoisted_1)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['promotion__padded-content'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['promotion__brif-content'])
                            }, [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(titleTag.value), {
                                    class: _normalizeClass({
                                        [_ctx.$style['promotions-shared__title']]: true,
                                        [_ctx.$style['promotions-shared__title--big']]: true,
                                        [_ctx.$style['promotion__bonus-title-name']]: true
                                    })
                                }, {
                                    default: _withCtx(()=>[
                                            _createTextVNode(_toDisplayString(bonusWallet.value.name), 1)
                                        ]),
                                    _: 1
                                }, 8, [
                                    "class"
                                ])),
                                bonusWallet.value.description ? (_openBlock(), _createBlock(PromotionDetailsDescription, {
                                    key: 0,
                                    description: bonusWallet.value.description,
                                    class: _normalizeClass(_ctx.$style['promotion__bonus-description'])
                                }, null, 8, [
                                    "description",
                                    "class"
                                ])) : _createCommentVNode("", true),
                                _createVNode(VButton, {
                                    class: _normalizeClass({
                                        [_ctx.$style['promotion__button']]: true,
                                        [_ctx.$style['promotion__button--bonus-wallet']]: true
                                    }),
                                    "full-width": false,
                                    label: _ctx.$t('WEB2_BONUS_ACTIONS'),
                                    onClick: onBonusWalletButtonClick
                                }, null, 8, [
                                    "class",
                                    "full-width",
                                    "label"
                                ])
                            ], 2),
                            _createVNode(_unref(PromotionDetailsHowToReceiveBonus), {
                                class: _normalizeClass(_ctx.$style['promotion__how-to-receive-bonus']),
                                title: bonusWallet.value.bonusTitle ?? void 0,
                                items: bonusWallet.value.bonusItems || []
                            }, null, 8, [
                                "class",
                                "title",
                                "items"
                            ])
                        ], 2),
                        _createVNode(VHowToHintBlock, {
                            class: _normalizeClass(_ctx.$style['promotion__how-to-hint']),
                            "background-image": "bonus-wallet-how-to.png",
                            title: _ctx.$t('WEB2_HOW_TO_USE_BONUSES'),
                            description: _ctx.$t('WEB2_HOW_TO_USE_BONUSES_HINT'),
                            "step-one": _ctx.$t('WEB2_HOW_TO_USE_BONUSES_STEP1'),
                            "step-two": _ctx.$t('WEB2_HOW_TO_USE_BONUSES_STEP2')
                        }, null, 8, [
                            "class",
                            "title",
                            "description",
                            "step-one",
                            "step-two"
                        ]),
                        bonusWallet.value.howToUseBonusWidget ? (_openBlock(), _createBlock(VDynamicContent, {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['promotion__term-links']),
                            content: bonusWallet.value.howToUseBonusWidget
                        }, null, 8, [
                            "class",
                            "content"
                        ])) : _createCommentVNode("", true)
                    ], 2)) : _createCommentVNode("", true),
                    isReady.value ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_unref(VLoaderDelayed))
                    ]))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusWalletRoutePage'
                ]
            ]);
        };
    }
});
