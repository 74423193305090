import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { VIcon } from '@components/v-icon';
import { useVHowToHintBlock } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VHowToHintBlock',
    props: {
        title: {
            default: ''
        },
        description: {
            default: ''
        },
        stepOne: {
            default: ''
        },
        stepTwo: {
            default: ''
        },
        backgroundImage: {
            default: ''
        }
    },
    setup (__props) {
        const props = __props;
        const { iconStepOneProps, iconStepTwoProps, iconArrowStepProps, styleProperties } = useVHowToHintBlock(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("section", {
                class: _normalizeClass(_ctx.$style['how-to-hint']),
                style: _normalizeStyle(_unref(styleProperties))
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['how-to-hint__info-block'])
                }, [
                    _createElementVNode("h2", {
                        class: _normalizeClass(_ctx.$style['how-to-hint__heading'])
                    }, _toDisplayString(_ctx.title), 3),
                    _createElementVNode("p", {
                        class: _normalizeClass(_ctx.$style['how-to-hint__text'])
                    }, _toDisplayString(_ctx.description), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['how-to-hint__steps'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['how-to-hint__step'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['how-to-hint__step-icon'])
                            }, [
                                _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(iconStepOneProps))), null, 16)
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['how-to-hint__step-text'])
                            }, _toDisplayString(_ctx.stepOne), 3)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['how-to-hint__step-sep'])
                        }, [
                            _createVNode(_unref(VIcon), _mergeProps(_unref(iconArrowStepProps), {
                                class: _ctx.$style['how-to-hint__step-sep-icon']
                            }), null, 16, [
                                "class"
                            ])
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['how-to-hint__step'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['how-to-hint__step-icon'])
                            }, [
                                _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(iconStepTwoProps))), null, 16)
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['how-to-hint__step-text'])
                            }, _toDisplayString(_ctx.stepTwo), 3)
                        ], 2)
                    ], 2)
                ], 2)
            ], 6)), [
                [
                    _directive_auto_id,
                    'VHowToHintBlock'
                ]
            ]);
        };
    }
});
