import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionRateButton',
    props: {
        likes: {
            default: 0
        },
        isLikeButton: {
            type: Boolean
        },
        isPressed: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const buttonProperties = {
            kind: ButtonKind.BASE,
            height: ButtonHeight.LARGE,
            rounded: true
        };
        const iconProperties = computed(()=>({
                name: props.isLikeButton ? IconName.LIKE : IconName.DISLIKE,
                size: IconSize.SIZE_16
            }));
        computed(()=>({
                name: props.isLikeButton ? IconName.SLOTT_LIKE_OUTLINE : IconName.SLOTT_DISLIKE_OUTLINE,
                size: IconSize.SIZE_16
            }));
        return (_ctx, _cache)=>(_openBlock(), _createBlock(LButton, _mergeProps({
                key: 1,
                class: {
                    [_ctx.$style['promotion-rate-button']]: true,
                    [_ctx.$style['promotion-rate-button--have-likes']]: _ctx.likes > 0,
                    [_ctx.$style['promotion-rate-button--pressed']]: _ctx.isPressed
                }
            }, buttonProperties, {
                disabled: _ctx.disabled,
                onClick: _cache[1] || (_cache[1] = ($event)=>emit('click'))
            }), {
                default: _withCtx(()=>[
                        _createVNode(_unref(VIcon), _mergeProps(iconProperties.value, {
                            class: _ctx.$style['promotion-rate-button__icon']
                        }), null, 16, [
                            "class"
                        ]),
                        _ctx.likes > 0 ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['promotion-rate-button__label'])
                        }, _toDisplayString(_ctx.likes), 3)) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 16, [
                "class",
                "disabled"
            ]));
    }
});
