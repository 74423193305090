import { computed } from 'vue';
import { RequirementValueType } from '@leon-hub/api-sdk';
import getProgressProperties from 'web/src/components/TermsSteps/VTermsStepProgress/composables/utils/getProgressProperties';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default function useVTermsStepProgress(props) {
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const progressProperties = computed(()=>getProgressProperties(props));
    const betsTotalParams = computed(()=>({
            betsTotal: props.requiredValue ? props.requiredValue : ''
        }));
    const total = computed(()=>{
        if (props.valueType === RequirementValueType.MONEY) return $formatMoney(Number(props.requiredValue));
        if (props.requiredValue) return $translate('WEB2_BONUS_WIDGET_BETS_REMAINING_COUNT', betsTotalParams).value;
        return '';
    });
    return {
        progressProperties,
        total
    };
}
