import { ref, computed, toRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { AlertIconName, IconName } from '@leon-hub/icons';
import { PromoActionType, RemoteApiErrorExceptionCode, SportBonusState } from '@leon-hub/api-sdk';
import { normalizeError } from '@leon-hub/errors';
import { TargetParamEnter } from '@leon-hub/yandex-metrika';
import { isOptionalString, isString } from '@leon-hub/guards';
import { ApiError } from '@leon-hub/api';
import GqlApiPromotionNotFoundError from '@leon-hub/api/src/client/graphql/errors/GqlApiPromotionNotFoundError';
import RouteName from '@leon-hub/routing-config-names';
import isGeneralApiError from '@leon-hub/api/src/client/errors/isGeneralApiError';
import { getImageOrIcon } from 'web/src/modules/icons';
import { getLeaderBoardList, getLeaderBoardOptions } from 'web/src/modules/promotions/store/utils';
import { ModalSelector } from 'web/src/modules/core/enums';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useLeaderBoardStore, useCashbackStore, usePromotionsStore } from 'web/src/modules/promotions/store';
import { useNavigationItemsStore, useRouterStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { DialogAction, DialogComponent, PresetName } from 'web/src/modules/dialogs/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useUserStore } from 'web/src/modules/user/store';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { usePromotionDetailsCore } from 'web/src/modules/promotions/submodules/details/composables';
import useIdentificationNeededModal from 'web/src/modules/dialogs/composables/useIdentificationNeededModal';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
// eslint-disable-next-line max-len,sonarjs/cognitive-complexity
export default function usePromotionDetailsRoutePage() {
    const cashbackStore = useCashbackStore();
    const cashbackAmountStore = toRef(cashbackStore, 'cashbackAmount');
    const cashback = toRef(cashbackStore, 'cashback');
    const isCashbackBeforeStart = toRef(cashbackStore, 'isCashbackBeforeStart');
    const cashbackCounterTimestamp = toRef(cashbackStore, 'cashbackCounterTimestamp');
    const actualRoundStartDate = toRef(cashbackStore, 'actualRoundStartDate');
    const isCurrentCashback = toRef(cashbackStore, 'isCurrentCashback');
    const { $translate } = useI18n();
    const analytics = useAnalytics();
    const route = useRoute();
    const router = useRouter();
    const { showDialog, closeDialog } = useDialogs();
    const errorsConverter = useErrorsConverter();
    const { showIdentificationNeededModal } = useIdentificationNeededModal();
    const { isLeaderBoardPromo, actionUrl, currentPromoLeaderboardDate, doButtonAction, scrollToLeaderBoard, goToCustomLink, loadMore, goToPlay, goToIdentification, promotionButton, isDoingActionButton, scrollToTerms, terms, board } = usePromotionDetailsCore();
    const userStore = useUserStore();
    const scgStore = useSiteConfigStore();
    const routerStore = useRouterStore();
    const pinCodeModule = usePinCodeStore();
    const promotionsBlock = toRef(scgStore, 'promotionsBlock');
    const isPromotionsRequestNicknameEnabled = toRef(scgStore, 'isPromotionsRequestNicknameEnabled');
    const customerDataStore = useCustomerDataStore();
    const customerNickname = toRef(customerDataStore, 'nickname');
    const promotionsStore = usePromotionsStore();
    const currentPromotion = toRef(promotionsStore, 'promotion');
    const promotionsHasCounter = toRef(promotionsStore, 'hasCounter');
    const promotionsIsBeforeStart = toRef(promotionsStore, 'isBeforeStart');
    const promotionsCounterTimestamp = toRef(promotionsStore, 'counterTimestamp');
    const isBlockParticipationButton = toRef(promotionsStore, 'isBlockParticipationButton');
    const isCustomerParticipating = toRef(promotionsStore, 'isCustomerParticipating');
    const { isLoggedIn } = useIsLoggedIn();
    const promotionCategoriesNavigationItems = toRef(useNavigationItemsStore(), 'promotionCategoriesNavigationItems');
    const leaderBoardStore = useLeaderBoardStore();
    const leaderBoardListMap = toRef(leaderBoardStore, 'leaderBoardListMap');
    const leaderBoardOptionsMapRecord = toRef(leaderBoardStore, 'leaderBoardOptionsMapRecord');
    const previousRouteName = toRef(routerStore, 'previousRouteName');
    const hasLoginPinCode = computed(()=>!!pinCodeModule.pinCodeRequestedAtLogin);
    const contentLoaded = ref(false);
    const spoilerRecord = ref({});
    const nickname = ref('');
    const isNicknameModal = ref(false);
    const isNicknameFlowFinal = ref(false);
    const isInputButtonDisabled = ref(false);
    const customErrors = ref({});
    const isCashbackInfoModal = ref(false);
    const isCashbackInfoModalLoading = ref(false);
    const isCashbackRefuseDialogLoading = ref(false);
    const tournamentPropertiesCache = {};
    const modalSelector = ModalSelector.BODY;
    const schema = {
        $schema: 'http://json-schema.org/draft-07/schema#',
        type: 'object',
        required: [
            'nickname'
        ],
        properties: {
            nickname: {
                type: 'string'
            }
        }
    };
    const isCashbackPromo = computed(()=>currentPromotion.value?.promotionType === PromoActionType.EGS_CASHBACK);
    const cashbackAmount = computed(()=>cashbackAmountStore.value);
    const isPromotionsLeaderboardArchiveEnabled = computed(()=>!!promotionsBlock.value?.isPromotionsLeaderboardArchiveEnabled);
    const showBadges = computed(()=>!!currentPromotion.value?.isHot || !!currentPromotion.value?.isNew || !!currentPromotion.value?.isNT);
    const isCashback = computed(()=>isCashbackPromo.value);
    const isCustomerParticipatingCashback = computed(()=>isCashback.value && isCustomerParticipating.value);
    const hasCounter = computed(()=>promotionsHasCounter.value || isCustomerParticipatingCashback.value);
    const imageProps = computed(()=>({
            isLazy: true,
            showLoader: true,
            objectFit: ObjectFitOption.COVER,
            ...currentPromotion.value?.shortBannerImage ?? {
                src: ''
            }
        }));
    const isBeforeStart = computed(()=>isCustomerParticipatingCashback.value ? isCashbackBeforeStart.value : promotionsIsBeforeStart.value);
    const counterTimestamp = computed(()=>isCustomerParticipatingCashback.value ? cashbackCounterTimestamp.value : promotionsCounterTimestamp.value);
    const disabledStatus = computed(()=>isLoggedIn.value && !!isBlockParticipationButton.value && hasCounter.value && isBeforeStart.value);
    const buttonProperties = computed(()=>promotionButton.value ? {
            buttonItem: promotionButton.value,
            isDisabled: disabledStatus.value
        } : null);
    const leaderBoardProperties = computed(()=>{
        const url = actionUrl.value ?? '';
        const list = getLeaderBoardList(url, leaderBoardListMap.value);
        const options = getLeaderBoardOptions(url, url, leaderBoardOptionsMapRecord.value);
        return {
            list,
            endOfList: options?.nextPage === 0,
            isLoading: options?.isLoading,
            customerPosition: options?.customerPosition ?? void 0
        };
    });
    const pageName = route.name === RouteName.PROMOTION_DETAILS ? $translate('WEB2_PAGE_NAME_ALL_PROMOTIONS') : $translate('WEB2_PAGE_NAME_ALL_TOURNAMENTS');
    const showCountdown = computed(()=>hasCounter.value);
    const showCashbackHistory = computed(()=>isCustomerParticipatingCashback.value);
    function tournamentProperties(id) {
        const stringId = String(id);
        if (!tournamentPropertiesCache[stringId]) tournamentPropertiesCache[stringId] = computed(()=>{
            const list = getLeaderBoardList(stringId, leaderBoardListMap.value);
            const options = getLeaderBoardOptions(actionUrl.value || '', stringId, leaderBoardOptionsMapRecord.value);
            return {
                list,
                endOfList: options?.nextPage === 0,
                isLoading: options?.isLoading,
                customerPosition: options?.customerPosition ?? void 0
            };
        });
        return tournamentPropertiesCache[stringId];
    }
    const isLeaderBoard = computed(()=>getLeaderBoardList(actionUrl.value || '', leaderBoardListMap.value).length > 0);
    const isShowTermsData = computed(()=>currentPromotion.value?.sportBonus?.state !== SportBonusState.CLOSED && (!!currentPromotion.value?.sportBonus?.bonusRequirements.length || !!currentPromotion.value?.sportBonus?.wager?.requirements.length) && currentPromotion.value?.promotionType === PromoActionType.SBC);
    const termsData = computed(()=>({
            sportBonus: currentPromotion.value?.sportBonus ?? void 0,
            termsKey: currentPromotion.value?.termsOfActions || ''
        }));
    const isReady = computed(()=>currentPromotion.value?.actionUrl !== void 0);
    const isArchived = computed(()=>!!currentPromotion.value?.isArchived);
    const isShowPromoSocialLinks = computed(()=>(currentPromotion.value?.socialLinks && currentPromotion.value.socialLinks.length > 0) ?? false);
    const rounds = computed(()=>{
        const cashbackRounds = cashback.value.cashback?.rounds;
        if (!!cashbackRounds && cashbackRounds?.length) return cashbackRounds;
    });
    const finalModalConfirmMessageBtrParams = computed(()=>({
            nickname: nickname.value
        }));
    const finalConfirmMessage = $translate('WEB2_NICKNAME_SAVED_MSG', finalModalConfirmMessageBtrParams);
    const modalProps = computed(()=>{
        const initialModal = {
            iconName: IconName.PERSON,
            width: ModalWidth.SMALL,
            isCentered: true,
            title: $translate('WEB2_CREATE_NICKNAME').value,
            confirmMessage: $translate('WEB2_NICKANAME_PROPOSAL').value,
            buttons: [
                {
                    kind: ButtonKind.TRANSPARENT,
                    action: DialogAction.MODAL_CLOSE,
                    label: $translate('WEB2_LATER').value
                }
            ]
        };
        const finalModal = {
            iconName: IconName.CHECK_OUTLINE,
            iconKind: JumbotronIconKind.SUCCESS,
            width: ModalWidth.SMALL,
            isCentered: true,
            title: $translate('WEB_NICKNAME_SAVED').value,
            confirmMessage: finalConfirmMessage.value,
            buttons: [
                {
                    kind: ButtonKind.PRIMARY,
                    action: DialogAction.MODAL_CLOSE,
                    label: $translate('JSP_PCL_FBOT_CLOSE').value
                }
            ]
        };
        return {
            modal: isNicknameFlowFinal.value ? finalModal : initialModal
        };
    });
    const modalCashbackInfoProps = computed(()=>({
            iconName: IconName.ATTENTION_OUTLINE,
            width: ModalWidth.SMALL,
            isCentered: true,
            title: $translate('WEB2_CASHBACK_IMPORTANT_INFO_TITLE').value,
            confirmMessage: $translate('WEB2_CASHBACK_IMPORTANT_INFO_TEXT').value,
            buttons: [
                {
                    kind: ButtonKind.PRIMARY,
                    action: DialogAction.CONFIRM,
                    label: $translate('WEB2_CASHBACK_IMPORTANT_INFO_ACCEPT').value,
                    isLoading: isCashbackInfoModalLoading.value
                },
                {
                    kind: ButtonKind.TRANSPARENT,
                    action: DialogAction.MODAL_CLOSE,
                    label: $translate('WEB2_CLOSE').value
                }
            ]
        }));
    function goToLoginPage() {
        analytics.clickMap({
            enter: TargetParamEnter.PROMO_PAGE
        });
        router.push({
            name: RouteName.LOGIN
        });
    }
    const routeActionUrl = computed(()=>{
        const { actionUrl: actionUrlParam } = route.params;
        isOptionalString(actionUrlParam);
        return actionUrlParam || null;
    });
    async function getData() {
        if (!routeActionUrl.value) return;
        await initialDataRequest();
        sendYM();
        const scrollToTermsQuery = router.getQuery('scrollToTerms');
        if (scrollToTermsQuery) {
            await router.replace({
                query: {}
            });
            setTimeout(scrollToTerms, 0);
        }
    }
    const navigationItem = computed(()=>{
        const { categoryId } = route.params;
        return promotionCategoriesNavigationItems.value.find((item)=>item.id === categoryId) ?? null;
    });
    function sendYM() {
        if (navigationItem.value?.caption && currentPromotion.value?.name) promotionsStore.promotionsSendYM({
            categoryName: navigationItem.value?.caption,
            promotionName: currentPromotion.value?.name
        });
    }
    function showErrorModal(rawError) {
        let params = null;
        if (rawError instanceof ApiError && 'PROMOTION_IS_UNAVAILABLE' === String(rawError.code)) params = {
            title: $translate('WEB2_PROMO_UNAVAILABLE_TITLE').value,
            confirmMessage: $translate('WEB2_PROMO_UNAVAILABLE_MESSAGE').value,
            image: getImageOrIcon({
                alertIcon: AlertIconName.Fail
            }).image
        };
        if (rawError instanceof GqlApiPromotionNotFoundError) {
            if (String(rawError.code) === RemoteApiErrorExceptionCode.PROMOTION_NOT_FOUND) params = {
                title: $translate('WEB2_PROMO_NOT_FOUND_TITLE').value,
                confirmMessage: $translate('WEB2_PROMO_NOT_FOUND_WRONG_URL_MESSAGE').value,
                image: getImageOrIcon({
                    alertIcon: AlertIconName.Fail
                }).image
            };
            else {
                router.replace({
                    name: RouteName.PROMOTIONS
                });
                return;
            }
        }
        if (params) {
            const { subscribe, id } = showDialog({
                presetName: PresetName.CONFIRM,
                options: {
                    title: params.title,
                    image: params.image,
                    confirmMessage: params.confirmMessage,
                    width: ModalWidth.SMALL,
                    buttons: [
                        {
                            label: $translate('WEB2_OTHER_PROMOS_BUTTON').value
                        },
                        {
                            label: $translate('WEB2_CLOSE').value
                        }
                    ],
                    dataTestId: 'promo-error'
                }
            });
            subscribe({
                [DialogAction.CONFIRM]: ()=>{
                    closeDialog(id);
                    router.replace({
                        name: RouteName.PROMOTIONS
                    });
                },
                [DialogAction.MODAL_CLOSE]: ()=>{
                    closeDialog(id);
                    router.replace({
                        name: RouteName.PROMOTIONS
                    });
                }
            });
            return;
        }
        throw rawError;
    }
    async function initialDataRequest() {
        try {
            actionUrl.value = routeActionUrl.value ?? '';
            if (actionUrl.value) {
                isString(actionUrl.value);
                leaderBoardStore.resetLeaderBoard();
                spoilerRecord.value = {};
                await Promise.all([
                    handleLoadPromotionDetails(actionUrl.value),
                    leaderBoardStore.getLeaderBoard({
                        actionUrl: actionUrl.value,
                        id: actionUrl.value,
                        isUpdateAfterLogin: true
                    })
                ]);
            }
        } catch (rawError) {
            showErrorModal(rawError);
        }
    }
    async function handleLoadPromotionDetails(actionUrlParam) {
        try {
            await promotionsStore.loadPromotionDetailsWithPolling({
                actionUrl: actionUrlParam,
                initial: true
            });
            if (isLoggedIn.value) await cashbackStore.getCashback();
        } catch (rawError) {
            showErrorModal(rawError);
        } finally{
            contentLoaded.value = true;
        }
    }
    async function onSpoilerOpen(id) {
        if (void 0 !== spoilerRecord.value[id]) spoilerRecord.value[id] = !spoilerRecord.value[id];
        else {
            await loadMore(id);
            spoilerRecord.value[id] = true;
        }
    }
    async function sendRequest() {
        if ('' !== actualRoundStartDate.value) await initialDataRequest();
    }
    function refuseCashback() {
        "1";
        {
            const modalProperties = {
                presetName: PresetName.CONFIRM,
                options: {
                    title: $translate('WEB2_BCAMPAIGN_OPT_OUT_CONFIRMATION_TITLE').value,
                    confirmMessage: $translate('WEB2_BONUSES_REFUSE_CASHBACK_CONFIRM_MESSAGE').value,
                    modalComponent: DialogComponent.ConfirmModal,
                    width: ModalWidth.SMALL,
                    image: getImageOrIcon({
                        alertIcon: AlertIconName.QuestionMark
                    }).image,
                    buttons: [
                        {
                            label: $translate('WEB2_BONUSES_REFUSE_BUTTON').value
                        }
                    ]
                }
            };
            try {
                isCashbackRefuseDialogLoading.value = true;
                const { subscribe } = showDialog(modalProperties);
                subscribe({
                    [DialogAction.CONFIRM]: async ()=>{
                        await Promise.all([
                            cashbackStore.refuseCashback(),
                            initialDataRequest()
                        ]).finally(()=>{
                            isCashbackRefuseDialogLoading.value = false;
                        });
                    },
                    [DialogAction.MODAL_CLOSE]: ()=>{
                        isCashbackRefuseDialogLoading.value = false;
                    }
                });
            } catch (rawError) {
                const error = normalizeError(rawError);
                if (isGeneralApiError(error)) throw error;
            }
        }
    }
    async function confirmCashbackParticipation() {
        try {
            isCashbackInfoModalLoading.value = true;
            await Promise.all([
                cashbackStore.confirmCashback(),
                cashbackStore.getCashback(),
                initialDataRequest()
            ]);
            cashbackStore.setCashbackRefused(false);
            isCashbackInfoModalLoading.value = false;
        } catch (rawError) {
            isCashbackInfoModalLoading.value = false;
            const error = normalizeError(rawError);
            if (isGeneralApiError(error)) throw error;
        }
    }
    async function onButtonClick(param) {
        let { action } = param;
        if (action === DialogAction.CONFIRM) {
            await confirmCashbackParticipation();
            onClose();
        }
        if (action === DialogAction.MODAL_CLOSE) onClose();
    }
    const uiSchema = computed(()=>({
            submitButton: {
                label: $translate('WEB2_CREATE_BONUS_CODE').value,
                disabled: isInputButtonDisabled.value
            },
            fields: {
                nickname: {
                    options: {
                        placeholder: $translate('WEB2_NICKNAME').value
                    }
                }
            }
        }));
    function onInput() {
        isInputButtonDisabled.value = false;
    }
    async function onSubmit(data) {
        if (!data.errors) {
            const { nickname: nicknameForm } = data.formData;
            if ('string' == typeof nicknameForm) try {
                await userStore.updateNickname({
                    nickname: nicknameForm
                });
                nickname.value = nicknameForm;
                isNicknameFlowFinal.value = true;
                await userStore.loadCustomerData();
            } catch (rawError) {
                const error = errorsConverter.convertToBaseError(rawError);
                isInputButtonDisabled.value = true;
                customErrors.value = {
                    nickname: [
                        {
                            value: nicknameForm,
                            message: error.message
                        }
                    ]
                };
            }
        }
    }
    async function onLater() {
        onClose();
        await doButtonAction();
    }
    function onClose() {
        isNicknameModal.value = false;
        isCashbackInfoModal.value = false;
    }
    async function preDoButtonAction() {
        if (!customerNickname.value && isLeaderBoardPromo.value && isPromotionsRequestNicknameEnabled.value) isNicknameModal.value = true;
        else if (isCashbackPromo.value) {
            if (promotionsBlock.value?.isCashbackLoyaltyModalEnabled) isCashbackInfoModal.value = true;
            else await confirmCashbackParticipation();
        } else await doButtonAction();
    }
    function onGoBackClick() {
        const { categoryId } = currentPromotion.value;
        if (previousRouteName.value) router.back();
        else if (route.name === RouteName.PROMOTION_DETAILS) router.push({
            name: RouteName.PROMOTIONS,
            params: {
                categoryId
            }
        });
        else router.push({
            name: RouteName.TOURNAMENTS,
            params: {
                categoryId
            }
        });
    }
    function onTermsClick() {
        if (termsData.value.termsKey) router.push({
            name: RouteName.CMS_PROMO_TERMS,
            params: {
                cmsKey: termsData.value.termsKey
            }
        });
    }
    function beforeMount() {
        getData();
    }
    function beforeUnmount() {
        promotionsStore.setOpenedActiveUrl('');
    }
    watch(routeActionUrl, getData);
    watch(isLoggedIn, ()=>{
        if (hasLoginPinCode.value) router.closeModal();
        initialDataRequest();
    });
    watch(currentPromotion, (newValue)=>{
        if (newValue?.categoryId && newValue.categoryId !== route.params.categoryId) {
            const params = {
                name: RouteName.PROMOTION_DETAILS,
                params: {
                    categoryId: newValue.categoryId
                }
            };
            "1";
            router.push302(params);
        }
    }, {
        immediate: true
    });
    return {
        isReady,
        currentPromotion,
        showBadges,
        hasCounter,
        isBeforeStart,
        counterTimestamp,
        isCustomerParticipatingCashback,
        buttonProperties,
        isArchived,
        isDoingActionButton,
        contentLoaded,
        isShowTermsData,
        termsData,
        currentPromoLeaderboardDate,
        rounds,
        imageProps,
        isLeaderBoard,
        leaderBoardProperties,
        isPromotionsLeaderboardArchiveEnabled,
        spoilerRecord,
        isShowPromoSocialLinks,
        isNicknameModal,
        modalSelector,
        modalProps,
        isNicknameFlowFinal,
        schema,
        uiSchema,
        customErrors,
        terms,
        cashbackAmount,
        isCashbackInfoModal,
        modalCashbackInfoProps,
        board,
        isLoggedIn,
        isCurrentCashback,
        pageName,
        showCountdown,
        showCashbackHistory,
        isCashbackRefuseDialogLoading,
        sendRequest,
        preDoButtonAction,
        scrollToLeaderBoard,
        doButtonAction,
        goToLoginPage,
        scrollToTerms,
        goToCustomLink,
        goToPlay,
        goToIdentification,
        refuseCashback,
        loadMore,
        onSpoilerOpen,
        tournamentProperties,
        onClose,
        onLater,
        onInput,
        onSubmit,
        onButtonClick,
        beforeMount,
        beforeUnmount,
        onGoBackClick,
        onTermsClick,
        showIdentificationNeededModal
    };
}
