import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useNavigationItemsStore } from 'web/src/modules/core/store';
import { usePromotionsStore } from 'web/src/modules/promotions/store';
import { getMenu, getIsPromoMainPage } from './utils';
export default function usePromotionsSidebarRouteComponent() {
    const router = useRouter();
    const promotionCategoriesNavigationItems = toRef(useNavigationItemsStore(), 'promotionCategoriesNavigationItems');
    const participationNumber = toRef(usePromotionsStore(), 'participationNumber');
    const isPromoMainPage = computed(()=>getIsPromoMainPage(router.currentRoute.value.name, router.currentRoute.value.params.categoryId));
    const menu = computed(()=>getMenu(promotionCategoriesNavigationItems.value, router, router.currentRoute.value.name, router.currentRoute.value.params.actionUrl, router.currentRoute.value.params.categoryId, participationNumber.value));
    return {
        menu,
        isPromoMainPage
    };
}
