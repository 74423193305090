import { defineComponent as _defineComponent } from 'vue';
import { resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { ref, watch, useSlots, computed } from 'vue';
import { VEmpty } from 'web/src/components/Empty';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VRenderlessSpoiler',
    props: {
        opened: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const isOpen = ref(false);
        watch(()=>props.opened, (to)=>{
            isOpen.value = !!to;
        }, {
            immediate: true
        });
        const slots = useSlots();
        function toggle() {
            isOpen.value = !isOpen.value;
        }
        function open() {
            isOpen.value = true;
        }
        function close() {
            isOpen.value = false;
        }
        const component = computed(()=>slots.default ? slots.default({
                isOpen,
                toggle,
                open,
                close
            }) : VEmpty);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(component.value), null, null, 512)), [
                [
                    _directive_auto_id,
                    'VRenderlessSpoiler'
                ]
            ]);
        };
    }
});
