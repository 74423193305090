import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import RouteName from '@leon-hub/routing-config-names';
import TheSidebarMenu from 'web/src/components/SidebarMenu/TheSidebarMenu/TheSidebarMenu.vue';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import { usePromotionsSidebarRouteComponent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionsSidebarRouteComponent',
    setup (__props) {
        const { menu, isPromoMainPage } = usePromotionsSidebarRouteComponent();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(TheSidebarMenu, {
                menu: _unref(menu)
            }, {
                top: _withCtx(()=>[
                        _createVNode(VRouterLink, {
                            class: _normalizeClass({
                                [_ctx.$style['sidebar-caption']]: true,
                                [_ctx.$style['sidebar-caption--active']]: _unref(isPromoMainPage)
                            }),
                            to: _ctx.$to(_unref(RouteName).PROMOTIONS)
                        }, {
                            default: _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_PROMO_SECTION_TITLE')), 1)
                                ]),
                            _: 1
                        }, 8, [
                            "class",
                            "to"
                        ])
                    ]),
                _: 1
            }, 8, [
                "menu"
            ])), [
                [
                    _directive_auto_id,
                    'PromotionsSidebarRouteComponent'
                ]
            ]);
        };
    }
});
