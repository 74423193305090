import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import usePromotionCashbackHistory from 'web/src/modules/promotions/components/PromotionCashbackHistory/composables/usePromotionCashbackHistory';
import SBadge from 'web/src/components/Badge/SBadge/SBadge.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionCashbackHistory',
    props: {
        list: {},
        currentCashback: {}
    },
    setup (__props) {
        const props = __props;
        const { formattedEndDate, isCurrentCashback, formattedItemCashbackAmount, currentCashbackFormatted } = usePromotionCashbackHistory(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['promotion-cashback-history'])
            }, [
                _createElementVNode("h3", {
                    class: _normalizeClass(_ctx.$style['promotion-cashback-history__title'])
                }, _toDisplayString(_ctx.$t('WEB2_PROMOTION_CASHBACK_TITLE')), 3),
                _createElementVNode("ul", {
                    class: _normalizeClass(_ctx.$style['promotion-cashback-history__list'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index)=>(_openBlock(), _createElementBlock("li", {
                            key: index,
                            class: _normalizeClass({
                                [_ctx.$style['promotion-cashback-history__item']]: true,
                                [_ctx.$style['promotion-cashback-history__item--active']]: _unref(isCurrentCashback)(item.roundEndDate)
                            })
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['promotion-cashback-history__left'])
                            }, [
                                _unref(isCurrentCashback)(item.roundEndDate) ? (_openBlock(), _createElementBlock("p", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['promotion-cashback-history__label'])
                                }, _toDisplayString(_ctx.$t('WEB2_CURRENT_CASHBACK')), 3)) : _createCommentVNode("", true),
                                _unref(isCurrentCashback)(item.roundEndDate) ? (_openBlock(), _createElementBlock("span", {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['promotion-cashback-history__add'])
                                }, _toDisplayString(_ctx.$t('WEB2_CASHBACK_ADD')), 3)) : _createCommentVNode("", true),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['promotion-cashback-history__date'])
                                }, _toDisplayString(_unref(formattedEndDate)(item.roundEndDate)), 3)
                            ], 2),
                            (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['promotion-cashback-history__amount'])
                            }, [
                                _unref(isCurrentCashback)(item.roundEndDate) ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    _createTextVNode(_toDisplayString(_unref(currentCashbackFormatted)), 1)
                                ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 1
                                }, [
                                    _createTextVNode(_toDisplayString(_unref(formattedItemCashbackAmount)(item.cashbackAmount)), 1)
                                ], 64))
                            ], 2))
                        ], 2))), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PromotionCashbackHistory'
                ]
            ]);
        };
    }
});
