import { computed, onActivated, onMounted, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import { CategoryType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { onComponentDeactivated } from '@leon-hub/vue-utils';
import { usePromotionsStore } from 'web/src/modules/promotions/store';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import useIsLoggedIn from 'web/src/modules/auth/composables/useIsLoggedIn';
import { usePromotionsRoute } from 'web/src/modules/promotions/pages/composables';
export default function usePromotionsRoutePage() {
    const router = useRouter();
    const { $translate } = useI18n();
    const { isLoggedIn } = useIsLoggedIn();
    const promotionsStore = usePromotionsStore();
    const siteConfigStore = useSiteConfigStore();
    const { categoryId, isArchivedCategory, navigationItem, sendYM } = usePromotionsRoute();
    const promotionIsReady = toRef(promotionsStore, 'promotionIsReady');
    const isMyActions = toRef(promotionsStore, 'isMyActions');
    const getActivePromotions = toRef(promotionsStore, 'getActivePromotions');
    const finishedPromotions = toRef(promotionsStore, 'finishedPromotions');
    const promotionsLoaded = toRef(promotionsStore, 'promotionsLoaded');
    const promotionsByCategory = toRef(promotionsStore, 'promotionsByCategory');
    const customerCategoryFromPromotionStore = toRef(promotionsStore, 'customerCategory');
    const isPromotionsLikeEnabled = toRef(siteConfigStore, 'isPromotionsLikeEnabled');
    const isPageActive = ref(false);
    // eslint-disable-next-line max-len
    const filteredPromotionsByCategory = computed(()=>promotionsByCategory.value.filter((item)=>item.value.length > 0));
    const buttonProperties = computed(()=>({
            label: $translate('WEB2_PROMOTIONS_ARCHIVE').value,
            kind: ButtonKind.BASE,
            rounded: true
        }));
    const hasPromotions = computed(()=>getActivePromotions.value.length > 0);
    // eslint-disable-next-line max-len
    const archivedCategory = promotionsStore.categoryIdByCategoryType(CategoryType.ARCHIVED);
    // eslint-disable-next-line max-len
    const customerCategory = promotionsStore.categoryIdByCategoryType(CategoryType.CUSTOMER);
    const isCustomerCategory = computed(()=>categoryId.value === customerCategory.value);
    const actionCaption = computed(()=>navigationItem.value?.caption || '');
    const updatePromotions = ()=>{
        promotionsStore.getPromotionsByCategoryId(navigationItem.value?.id || '').finally(()=>{
            if (categoryId.value && !navigationItem.value?.id && !promotionsStore.promotion?.isArchived) router.replace({
                name: RouteName.PROMOTIONS
            });
        });
    };
    onMounted(()=>{
        isPageActive.value = true;
        if (null !== customerCategoryFromPromotionStore.value) {
            promotionsStore.fetchPromotions(customerCategoryFromPromotionStore.value);
            promotionsStore.getPromotionsByCategoryType(CategoryType.CUSTOMER_ARCHIVED);
        }
        updatePromotions();
    });
    onActivated(()=>{
        isPageActive.value = true;
    });
    onComponentDeactivated(()=>{
        isPageActive.value = false;
    });
    const onListEmptyClick = ()=>{
        router.push({
            name: RouteName.PROMOTIONS,
            params: {
                categoryId: archivedCategory.value
            }
        });
    };
    watch(isLoggedIn, ()=>{
        updatePromotions();
    });
    watch(isPageActive, (newValue)=>{
        if (newValue) {
            updatePromotions();
            sendYM();
        }
    });
    watch(categoryId, ()=>{
        if (isPageActive.value) {
            updatePromotions();
            sendYM();
        }
    }, {
        immediate: true,
        deep: true
    });
    return {
        promotionIsReady,
        actionCaption,
        hasPromotions,
        isMyActions,
        getActivePromotions,
        isArchivedCategory,
        isPromotionsLikeEnabled,
        isCustomerCategory,
        filteredPromotionsByCategory,
        finishedPromotions,
        buttonProperties,
        onListEmptyClick,
        promotionsLoaded,
        categoryId,
        sendYM,
        navigationItem
    };
}
