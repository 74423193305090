import { ref, computed, onMounted, toRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names/src';
import { PromoActionType } from '@leon-hub/api-sdk';
import { IconName } from '@leon-hub/icons';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
import { useCashbackStore, usePromotionsStore } from 'web/src/modules/promotions/store';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function usePromotionsListItem(props) {
    const route = useRoute();
    const router = useRouter();
    const promotionStore = usePromotionsStore();
    const i18nStore = useI18nStore();
    const cashbackStore = useCashbackStore();
    const isCashbackParticipatingConfirmed = toRef(cashbackStore, 'isCashbackParticipatingConfirmed');
    const isCashbackBeforeStart = toRef(cashbackStore, 'isCashbackBeforeStart');
    const timestampStartDate = toRef(cashbackStore, 'timestampStartDate');
    const timestampEndDate = toRef(cashbackStore, 'timestampEndDate');
    const { $translate } = useI18n();
    const locale = toRef(i18nStore, 'locale');
    const promotions = toRef(promotionStore, 'promotions');
    const promotion = computed(()=>promotions.value[props.promotionId]);
    const isCardFlipped = ref(false);
    computed(()=>route.name === RouteName.PROMOTIONS);
    const promotionStartDate = computed(()=>{
        if (promotion.value.startDateNumber) return DateTime.formatTimeStamp(promotion.value.startDateNumber, TimeFormats.dayMonthShortYear, locale.value);
        return '';
    });
    const promotionEndDate = computed(()=>{
        if (promotion.value.endDateNumber) return DateTime.formatTimeStamp(promotion.value.endDateNumber, TimeFormats.dayMonthShortYear, locale.value);
        return '';
    });
    const promotionDistance = computed(()=>{
        const now = Date.now();
        return promotion.value.endDateNumber ? parseInt(String((promotion.value.endDateNumber - now) / 60000), 10) : 0;
    });
    const showBadges = computed(()=>!!promotion.value?.isHot || !!promotion.value?.isNew || !!promotion.value?.isNT);
    const isCashback = computed(()=>promotion.value?.promotionType === PromoActionType.EGS_CASHBACK);
    onMounted(async ()=>{
        if (isCashback.value) await cashbackStore.getCashback();
    });
    const isCustomerParticipatingCashback = computed(()=>isCashback.value && isCashbackParticipatingConfirmed.value);
    const hasCounter = computed(()=>!!promotion.value?.isShowStartDateCountdown || !!promotion.value?.isShowEndDateCountdown || isCustomerParticipatingCashback.value);
    const isBeforeStart = computed(()=>{
        if (isCustomerParticipatingCashback.value) return isCashbackBeforeStart.value;
        return !!promotion.value?.isShowStartDateCountdown;
    });
    const timestamp = computed(()=>{
        if (isCustomerParticipatingCashback.value) {
            if (isBeforeStart.value) return timestampStartDate.value || 0;
            return timestampEndDate.value || 0;
        }
        if (isBeforeStart.value) return promotion.value?.startDateCountdown || 0;
        return promotion.value?.endDateNumber || 0;
    });
    const detailsLocation = computed(()=>{
        if (promotion.value) return {
            name: RouteName.PROMOTION_DETAILS,
            params: {
                categoryId: promotion.value?.categoryId || '',
                actionUrl: promotion.value?.actionUrl || ''
            }
        };
        return '';
    });
    const imageProps = computed(()=>({
            isLazy: true,
            showLoader: true,
            objectFit: ObjectFitOption.COVER,
            ...promotion.value?.shortBannerImage ?? {
                src: ''
            }
        }));
    const buttonFlipProps = computed(()=>({
            size: 'xsmall',
            iconName: isCardFlipped.value ? IconName.SLOTT_CROSS : IconName.SLOTT_INFO_1,
            kind: isCardFlipped.value ? 'quaternary-primary' : 'tertiary-primary'
        }));
    const textPosition = computed(()=>LanguageMicroSettings.plural([
            $translate('WEB2_PROMOTION_POSITION_ONE').value,
            $translate('WEB2_PROMOTION_POSITION_TWO').value,
            $translate('WEB2_PROMOTION_POSITION_MANY').value,
            $translate('WEB2_PROMOTION_POSITION').value
        ], promotion.value?.position || 0, locale.value));
    const likes = computed(()=>promotion.value?.likes);
    const metaParameters = computed(()=>{
        const metaInfo = {
            promotionName: promotion.value?.name || '',
            promotionDescription: promotion.value?.metaDescription || '',
            promotionImage: promotion.value?.shortBannerUrl || promotion.value?.bannerUrl || '',
            promotionUrl: router.getHref(detailsLocation.value).absolute,
            // eslint-disable-next-line max-len
            promotionDateCreateISO: promotion.value?.startDateNumber ? new DateTime(promotion.value?.startDateNumber).toISOString() : '',
            promotionLocale: locale.value
        };
        return {
            promotions: {
                value: [
                    metaInfo
                ],
                merge (oldValue, newValue) {
                    return [
                        ...oldValue,
                        ...newValue
                    ];
                }
            }
        };
    });
    function buttonFlipClick() {
        isCardFlipped.value = !isCardFlipped.value;
    }
    async function sendRequest() {
        if ('' !== cashbackStore.actualRoundStartDate) await cashbackStore.getCashback();
    }
    return {
        metaParameters,
        showBadges,
        hasCounter,
        imageProps,
        detailsLocation,
        textPosition,
        likes,
        timestamp,
        promotion,
        isBeforeStart,
        isCustomerParticipatingCashback,
        promotionStartDate,
        promotionEndDate,
        promotionDistance,
        isCardFlipped,
        buttonFlipProps,
        buttonFlipClick,
        sendRequest
    };
}
