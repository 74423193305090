import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import { ref } from 'vue';
import VTermsSteps from 'web/src/components/TermsSteps/VTermsSteps/VTermsSteps.vue';
import usePromotionTerms from 'web/src/modules/promotions/components/PromotionTerms/composables/usePromotionTerms';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionTerms',
    props: {
        sportBonus: {},
        termsKey: {}
    },
    setup (__props, param) {
        let { expose: __expose } = param;
        const props = __props;
        const { requirementsData, showTerms, onReadMoreClick } = usePromotionTerms(props);
        const terms = ref();
        __expose({
            getOffsetTop: ()=>terms.value?.offsetTop || 0,
            scrollIntoView: ()=>terms.value?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                })
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(showTerms) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                ref_key: "terms",
                ref: terms,
                class: _normalizeClass(_ctx.$style['promotion-terms'])
            }, [
                _createElementVNode("h2", {
                    class: _normalizeClass({
                        [_ctx.$style['promotions-shared__title']]: true,
                        [_ctx.$style['promotion-terms__title']]: true
                    })
                }, _toDisplayString(_unref(requirementsData).title), 3),
                _createVNode(VTermsSteps, {
                    class: _normalizeClass(_ctx.$style['promotion-terms__steps']),
                    items: _unref(requirementsData).items,
                    "show-read-more-button": !!_ctx.termsKey,
                    onClick: _unref(onReadMoreClick)
                }, null, 8, [
                    "class",
                    "items",
                    "show-read-more-button",
                    "onClick"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'PromotionTerms'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
