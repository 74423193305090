import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { VIcon } from '@components/v-icon';
import VRenderlessSpoiler from 'web/src/components/Spoiler/components/VRenderlessSpoiler.vue';
import useLeaderBoardSpoiler from 'web/src/modules/promotions/components/LeaderBoardSpoiler/composables/useLeaderBoardSpoiler';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaderBoardSpoiler',
    props: {
        title: {
            default: ''
        },
        opened: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        "open"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { indicatorProperties } = useLeaderBoardSpoiler();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VRenderlessSpoiler, {
                opened: _ctx.opened
            }, {
                default: _withCtx((param)=>{
                    let { isOpen } = param;
                    return [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['leader-board-spoiler'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['leader-board-spoiler__header']),
                                onClick: _cache[0] || (_cache[0] = ($event)=>emit('open'))
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['leader-board-spoiler__title'])
                                }, _toDisplayString(_ctx.title), 3),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['leader-board-spoiler__indicator'])
                                }, [
                                    _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(isOpen ? _unref(indicatorProperties)[1] : _unref(indicatorProperties)[0])), null, 16)
                                ], 2)
                            ], 2),
                            _withDirectives(_createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['leader-board-spoiler__content'])
                            }, [
                                _renderSlot(_ctx.$slots, "default")
                            ], 2), [
                                [
                                    _vShow,
                                    isOpen
                                ]
                            ])
                        ], 2)
                    ];
                }),
                _: 3
            }, 8, [
                "opened"
            ])), [
                [
                    _directive_auto_id,
                    'LeaderBoardSpoiler'
                ]
            ]);
        };
    }
});
