import { computed } from 'vue';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default function usePromotionCashbackHistory(props) {
    const formatMoney = useFormatMoney();
    const currentCashbackFormatted = computed(()=>formatMoney(props.currentCashback));
    const formattedItemCashbackAmount = (amount)=>formatMoney(amount);
    const formattedEndDate = (date)=>{
        const timestamp = DateTime.toTimestamp(date);
        const format = TimeFormats.dayMonthShortYear;
        return DateTime.formatTimeStamp(timestamp, format);
    };
    const isCurrentCashback = (date)=>{
        const currentDateTime = Date.now();
        const timestamp = DateTime.toTimestamp(date);
        return timestamp >= currentDateTime;
    };
    return {
        formattedEndDate,
        isCurrentCashback,
        formattedItemCashbackAmount,
        currentCashbackFormatted
    };
}
