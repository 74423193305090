import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconSize } from '@leon-hub/icons';
import VSocial from 'web/src/components/Social/VSocial/VSocial.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionDetailsSocials',
    props: {
        promotion: {}
    },
    setup (__props) {
        function getSocialType(type) {
            return type ? type : void 0;
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['promotion-socials'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promotion.socialLinks, (item, index)=>(_openBlock(), _createBlock(VSocial, {
                        key: index,
                        class: _normalizeClass(_ctx.$style['promotion-socials__icon']),
                        "social-type": getSocialType(item.name),
                        url: `${item.url}`,
                        size: _unref(IconSize).SIZE_32
                    }, null, 8, [
                        "class",
                        "social-type",
                        "url",
                        "size"
                    ]))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'PromotionDetailsSocials'
                ]
            ]);
        };
    }
});
