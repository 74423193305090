import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, renderList as _renderList, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import PromotionsListEmpty from 'web/src/modules/promotions/components/PromotionsListEmpty/PromotionsListEmpty.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import PromotionsList from 'web/src/modules/promotions/components/PromotionsList/PromotionsList.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { usePromotionsRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionsRoutePage',
    setup (__props) {
        const { promotionIsReady, actionCaption, hasPromotions, isMyActions, getActivePromotions, isArchivedCategory, isPromotionsLikeEnabled, isCustomerCategory, filteredPromotionsByCategory, finishedPromotions, buttonProperties, onListEmptyClick, promotionsLoaded, categoryId } = usePromotionsRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['component-wrapper'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['promotions'])
                }, [
                    _unref(categoryId) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _unref(promotionIsReady) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['promotions__section-wrapper'])
                        }, [
                            _unref(actionCaption) && _unref(hasPromotions) && _unref(isMyActions) ? (_openBlock(), _createElementBlock("p", {
                                key: 0,
                                class: _normalizeClass([
                                    _ctx.$style['promotions-shared__title'],
                                    _ctx.$style['promotions-shared__title--left'],
                                    _ctx.$style['promotions__title']
                                ])
                            }, _toDisplayString(_unref(isMyActions) ? _ctx.$t('WEB2_ACTIVE') : _unref(actionCaption)), 3)) : _createCommentVNode("", true),
                            _unref(isArchivedCategory) ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                _unref(hasPromotions) ? (_openBlock(), _createBlock(PromotionsList, {
                                    key: 0,
                                    promotions: _unref(getActivePromotions),
                                    "is-promotions-like-enabled": _unref(isPromotionsLikeEnabled),
                                    "is-archived": "",
                                    class: _normalizeClass(_ctx.$style['promotions__list'])
                                }, null, 8, [
                                    "promotions",
                                    "is-promotions-like-enabled",
                                    "class"
                                ])) : (_openBlock(), _createBlock(PromotionsListEmpty, {
                                    key: 1,
                                    title: _ctx.$t('WEB2_PROMOTIONS_AND_BONUSES_NOT_FOUND'),
                                    description: _ctx.$t('WEB2_PROMOTIONS_AND_BONUSES_NOT_FOUND_DESC'),
                                    class: _normalizeClass(_ctx.$style['promotions__list-empty'])
                                }, null, 8, [
                                    "title",
                                    "description",
                                    "class"
                                ]))
                            ], 64)) : _unref(filteredPromotionsByCategory).length ? (_openBlock(true), _createElementBlock(_Fragment, {
                                key: 2
                            }, _renderList(_unref(filteredPromotionsByCategory), (item)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                                    item.value.length ? (_openBlock(), _createElementBlock("div", {
                                        key: item.id
                                    }, [
                                        _unref(isCustomerCategory) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("p", {
                                            key: 0,
                                            class: _normalizeClass([
                                                _ctx.$style['promotions-shared__title'],
                                                _ctx.$style['promotions-shared__title--left'],
                                                _ctx.$style['promotions__title']
                                            ])
                                        }, _toDisplayString(item.name), 3)),
                                        _createVNode(PromotionsList, {
                                            promotions: item.value,
                                            "is-promotions-like-enabled": _unref(isPromotionsLikeEnabled),
                                            "is-archived": false,
                                            class: _normalizeClass(_ctx.$style['promotions__list'])
                                        }, null, 8, [
                                            "promotions",
                                            "is-promotions-like-enabled",
                                            "class"
                                        ])
                                    ])) : _createCommentVNode("", true)
                                ], 64))), 256)) : (_openBlock(), _createBlock(PromotionsListEmpty, {
                                key: 3,
                                title: _ctx.$t('WEB2_PROMOTIONS_AND_BONUSES_NOT_FOUND'),
                                description: _ctx.$t('WEB2_PROMOTIONS_AND_BONUSES_NOT_FOUND_DESC'),
                                class: _normalizeClass(_ctx.$style['promotions__list-empty'])
                            }, {
                                button: _withCtx(()=>[
                                        _unref(isArchivedCategory) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(VButton, _mergeProps({
                                            key: 0
                                        }, _unref(buttonProperties), {
                                            class: _ctx.$style['promotions__button'],
                                            onClick: _unref(onListEmptyClick)
                                        }), null, 16, [
                                            "class",
                                            "onClick"
                                        ]))
                                    ]),
                                _: 1
                            }, 8, [
                                "title",
                                "description",
                                "class"
                            ]))
                        ], 2)) : _createCommentVNode("", true),
                        _unref(promotionIsReady) ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['promotions__section-wrapper'])
                        }, [
                            _unref(isMyActions) && _unref(finishedPromotions).length ? (_openBlock(), _createElementBlock("p", {
                                key: 0,
                                class: _normalizeClass([
                                    _ctx.$style['promotions-shared__title'],
                                    _ctx.$style['promotions-shared__title--left'],
                                    _ctx.$style['promotions__title']
                                ])
                            }, _toDisplayString(_ctx.$t('WEB2_FINISHED_PROMOTIONS')), 3)) : _createCommentVNode("", true),
                            _unref(isMyActions) && _unref(finishedPromotions).length ? (_openBlock(), _createBlock(PromotionsList, {
                                key: 1,
                                promotions: _unref(finishedPromotions),
                                "is-promotions-like-enabled": _unref(isPromotionsLikeEnabled),
                                "is-archived": "",
                                class: _normalizeClass(_ctx.$style['promotion__list'])
                            }, null, 8, [
                                "promotions",
                                "is-promotions-like-enabled",
                                "class"
                            ])) : _createCommentVNode("", true)
                        ], 2)) : _createCommentVNode("", true),
                        _unref(promotionIsReady) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            class: _normalizeClass(_ctx.$style['loader-masked'])
                        }, [
                            _createVNode(_unref(VLoaderDelayed))
                        ], 2))
                    ], 64)) : _createCommentVNode("", true),
                    _unref(categoryId) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        _unref(promotionsLoaded) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _unref(filteredPromotionsByCategory).length ? (_openBlock(true), _createElementBlock(_Fragment, {
                                key: 0
                            }, _renderList(_unref(filteredPromotionsByCategory), (item)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                                    item.value.length ? (_openBlock(), _createElementBlock("div", {
                                        key: item.id,
                                        class: _normalizeClass(_ctx.$style['promotions__section-wrapper'])
                                    }, [
                                        _createElementVNode("p", {
                                            class: _normalizeClass([
                                                _ctx.$style['promotions-shared__title'],
                                                _ctx.$style['promotions-shared__title--left'],
                                                _ctx.$style['promotions__title']
                                            ])
                                        }, _toDisplayString(item.name), 3),
                                        _createVNode(PromotionsList, {
                                            promotions: item.value,
                                            "is-promotions-like-enabled": _unref(isPromotionsLikeEnabled),
                                            "is-archived": false,
                                            class: _normalizeClass(_ctx.$style['promotions__list'])
                                        }, null, 8, [
                                            "promotions",
                                            "is-promotions-like-enabled",
                                            "class"
                                        ])
                                    ], 2)) : _createCommentVNode("", true)
                                ], 64))), 256)) : (_openBlock(), _createBlock(PromotionsListEmpty, {
                                key: 1,
                                title: _ctx.$t('WEB2_PROMOTIONS_AND_BONUSES_NOT_FOUND'),
                                description: _ctx.$t('WEB2_PROMOTIONS_AND_BONUSES_NOT_FOUND_DESC'),
                                class: _normalizeClass(_ctx.$style['promotions__list-empty'])
                            }, {
                                button: _withCtx(()=>[
                                        _unref(isArchivedCategory) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(VButton, _mergeProps({
                                            key: 0
                                        }, _unref(buttonProperties), {
                                            class: _ctx.$style['promotions__button'],
                                            onClick: _unref(onListEmptyClick)
                                        }), null, 16, [
                                            "class",
                                            "onClick"
                                        ]))
                                    ]),
                                _: 1
                            }, 8, [
                                "title",
                                "description",
                                "class"
                            ]))
                        ], 64)) : (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['loader-masked'])
                        }, [
                            _createVNode(_unref(VLoaderDelayed))
                        ], 2))
                    ], 64))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PromotionsRoutePage'
                ]
            ]);
        };
    }
});
