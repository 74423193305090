import { CategoryType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
export default function resolveCategoryLink(item, router) {
    const options = {
        params: {
            categoryId: item.id
        }
    };
    if (item.props?.type === CategoryType.BONUS_WALLET) return router.resolve({
        name: RouteName.BONUS_WALLET
    });
    return router.resolve({
        name: RouteName.PROMOTIONS,
        ...options
    });
}
