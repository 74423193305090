import { computed, ref, toRef } from 'vue';
import { useRoute } from 'vue-router';
import { BonusCampaignType, CategoryType, PromoActionType, RefuseBonusType } from '@leon-hub/api-sdk';
import { normalizeError } from '@leon-hub/errors';
import { isOptionalString } from '@leon-hub/guards';
import { AlertIconName } from '@leon-hub/icons';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useNavigationStore } from 'web/src/modules/core/store';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, DialogComponent, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogsStore } from 'web/src/modules/dialogs/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getImageOrIcon } from 'web/src/modules/icons';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useProfileBonusesStore } from 'web/src/modules/profile/submodules/bonuses/store';
import { usePromotionsStore } from 'web/src/modules/promotions/store';
export default function usePromotionRefuseButton() {
    const refuseButtonLoading = ref(false);
    const { $translate } = useI18n();
    const promotionStore = usePromotionsStore();
    const route = useRoute();
    const dialogsStore = useDialogsStore();
    const profileBonusesStore = useProfileBonusesStore();
    const formatMoney = useFormatMoney();
    const { showDialog } = useDialogs();
    const analytics = useAnalytics();
    const navigationStore = useNavigationStore();
    const refuseButtonProperties = computed(()=>({
            label: $translate('WEB2_BONUSES_REFUSE_BUTTON').value,
            kind: ButtonKind.SECONDARY,
            isLoading: refuseButtonLoading.value,
            fullWidth: true
        }));
    const promotion = toRef(promotionStore, 'promotion');
    const bonusCampaignType = computed(()=>{
        if (promotion.value.promotionType === PromoActionType.SBC) return BonusCampaignType.SPORT;
        if (promotion.value.promotionType === PromoActionType.CBC) return BonusCampaignType.EGS;
        return null;
    });
    const isRefuseButtonAvailable = computed(()=>!!promotion.value.sportBonus?.refuseAvailable && null !== bonusCampaignType.value);
    const getRefuseTranslations = (refuseBonus)=>{
        const data = {
            title: $translate('WEB2_BCAMPAIGN_OPT_OUT_CONFIRMATION_TITLE').value,
            confirmMessage: ''
        };
        switch(refuseBonus.refuseType){
            case RefuseBonusType.ONLY_WRITEOFF:
                data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_FREEBET_WRITEOFF', ref({
                    amount: formatMoney(refuseBonus.writeOffAmount || 0)
                })).value;
                break;
            case RefuseBonusType.WRITEOFF_AND_DEPOSIT_RETURN:
                data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_WRITEOFF_AND_DEPOSIT_RETURN', ref({
                    depositAmount: formatMoney(refuseBonus.returnDepositAmount || 0),
                    writeOffAmount: formatMoney(refuseBonus.writeOffAmount || 0)
                })).value;
                break;
            case RefuseBonusType.FREEBET_CANCEL:
                data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_FREEBET_CANCEL', ref({
                    amount: formatMoney(refuseBonus.freebetAmount || 0)
                })).value;
                break;
            case RefuseBonusType.FREESPIN_CANCEL:
                data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_FREESPIN_CANCEL', ref({
                    amount: refuseBonus?.freespinCount?.toString() || '0'
                })).value;
                break;
            default:
                data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_NONE').value;
                break;
        }
        return data;
    };
    const onRefuseClick = async ()=>{
        let modalRefuseLoading = false;
        let modalId = '';
        const { actionUrl } = route.params;
        const bonusId = promotion.value.sportBonus?.bonusId || null;
        const modalPreset = dialogsStore.dialogPresets.CONFIRM;
        const modalPresetButtons = modalPreset.buttons ?? [];
        const modalProperties = {
            presetName: PresetName.CONFIRM,
            options: {
                ...modalPreset,
                image: getImageOrIcon({
                    alertIcon: AlertIconName.QuestionMark
                }).image,
                modalComponent: DialogComponent.ConfirmModal,
                width: ModalWidth.SMALL,
                buttons: [
                    {
                        label: $translate('WEB2_BONUSES_REFUSE_BUTTON').value,
                        isLoading: modalRefuseLoading
                    }
                ]
            }
        };
        if (bonusId && null !== bonusCampaignType.value) {
            refuseButtonLoading.value = true;
            const campaignType = bonusCampaignType.value;
            try {
                const refuseBonus = await profileBonusesStore.refuseBonus({
                    bonusId,
                    campaignType,
                    preview: true
                });
                const { title, confirmMessage } = getRefuseTranslations(refuseBonus);
                modalProperties.options.title = title;
                modalProperties.options.confirmMessage = confirmMessage;
                const { id, subscribe } = showDialog(modalProperties);
                modalId = id;
                subscribe({
                    [DialogAction.CONFIRM]: ()=>{
                        analytics.push(AnalyticsEvent.Z_CAMPAIGN_REJECTION, {
                            campaignRejection: true
                        });
                        modalRefuseLoading = true;
                        const newProps = {
                            buttons: [
                                {
                                    label: $translate('WEB2_BONUSES_REFUSE_BUTTON').value,
                                    isLoading: modalRefuseLoading
                                },
                                modalPresetButtons[1]
                            ]
                        };
                        dialogsStore.updateDialogProps(modalId, newProps);
                        profileBonusesStore.refuseBonus({
                            bonusId,
                            campaignType,
                            preview: false
                        }).then(async ()=>{
                            isOptionalString(actionUrl);
                            await Promise.all([
                                navigationStore.fetchPromotionsNavigationConfig(),
                                promotionStore.getPromotionsByCategoryType(CategoryType.CUSTOMER),
                                promotionStore.loadPromotionDetailsWithPolling({
                                    actionUrl,
                                    initial: true
                                })
                            ]);
                        }).finally(()=>{
                            refuseButtonLoading.value = false;
                            modalRefuseLoading = false;
                        });
                    },
                    [DialogAction.MODAL_CLOSE]: ()=>{
                        refuseButtonLoading.value = false;
                        modalRefuseLoading = false;
                    }
                });
            } catch (rawError) {
                const error = normalizeError(rawError);
                refuseButtonLoading.value = false;
                showDialog({
                    presetName: PresetName.ALERT_WARNING,
                    options: {
                        title: $translate('WEB2_BONUSES_REFUSE_FAILED_TITLE').value,
                        confirmMessage: error.message,
                        dataTestId: 'promo-bonuses-refuse-failed'
                    }
                });
            }
        }
    };
    return {
        isRefuseButtonAvailable,
        refuseButtonProperties,
        refuseButtonLoading,
        onRefuseClick
    };
}
