import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import SNumberedList from 'web/src/components/NumberedList/SNumberedList/SNumberedList.vue';
import SNumberedListItem from 'web/src/components/NumberedListItem/SNumberedListItem/SNumberedListItem.vue';
import LNumberedListItemProgress from 'web/src/components/NumberedListItem/SNumberedListItem/components/LNumberedListItemProgress/LNumberedListItemProgress.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionDetailsHowDoesItWork',
    props: {
        title: {
            default: ''
        },
        items: {},
        hasRefuseButton: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.title && _ctx.items.length ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['how-does-it-work'])
            }, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createElementVNode("h2", {
                        class: _normalizeClass({
                            [_ctx.$style['promotions-shared__title']]: true,
                            [_ctx.$style['how-does-it-work__title']]: true
                        })
                    }, _toDisplayString(_ctx.title), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['stage-hint'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index)=>(_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: _normalizeClass({
                                    [_ctx.$style['stage-hint__panel']]: true,
                                    [_ctx.$style['stage-hint__panel--column']]: false
                                })
                            }, [
                                (_openBlock(), _createBlock(LNumberedListItemProgress, {
                                    key: 0,
                                    step: (index + 1).toString()
                                }, null, 8, [
                                    "step"
                                ])),
                                _createVNode(VDynamicContent, {
                                    class: _normalizeClass(_ctx.$style['stage-hint__text']),
                                    content: item.howWorksItemDescription || ''
                                }, null, 8, [
                                    "class",
                                    "content"
                                ])
                            ], 2))), 128))
                    ], 2)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'PromotionDetailsHowDoesItWork'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
