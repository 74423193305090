import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { getStylesProperties } from 'web/src/components/HowToHintBlock/VHowToHintBlock/composables/utils';
export default function useVHowToHintBlock(props) {
    const iconStepOneProps = {
        name: IconName.MONEY_OFF,
        size: IconSize.SIZE_16
    };
    const iconStepTwoProps = {
        name: IconName.MONEY_ON,
        size: IconSize.SIZE_16
    };
    const iconArrowStepProps = {
        name: IconName.ARROW_DOWNWARD,
        size: IconSize.SIZE_16
    };
    const styleProperties = computed(()=>getStylesProperties(props));
    return {
        iconStepOneProps,
        iconStepTwoProps,
        iconArrowStepProps,
        styleProperties
    };
}
