import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, Teleport as _Teleport, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
import { onBeforeMount, onBeforeUnmount } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import PromotionDetailsRefuseButton from 'web/src/modules/promotions/components/PromotionRefuseButton/PromotionsRefuseButton.vue';
import PromotionCountdown from 'web/src/modules/promotions/components/PromotionCountdown/PromotionCountdown.vue';
import PromotionRate from 'web/src/modules/promotions/components/PromotionRate/PromotionRate.vue';
import LeaderBoardSpoiler from 'web/src/modules/promotions/components/LeaderBoardSpoiler/LeaderBoardSpoiler.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import PromotionDetailsSocials from 'web/src/modules/promotions/components/PromotionDetailsSocials/PromotionDetailsSocials.vue';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import PromotionDetailsHowToReceiveBonus from 'web/src/modules/promotions/components/PromotionDetailsHowToReceiveBonus';
import PromotionDetailsTerms from 'web/src/modules/promotions/components/PromotionTerms/PromotionTerms.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { VForm } from 'web/src/components/Form';
import PromotionCashbackHistory from 'web/src/modules/promotions/components/PromotionCashbackHistory/PromotionCashbackHistory.vue';
import LeaderBoardWrapper from 'web/src/modules/promotions/components/LeaderBoardWrapper/LeaderBoardWrapper.vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import PromotionDetailsHowDoesItWork from 'web/src/modules/promotions/components/PromotionDetailsHowDoesItWork';
import PromotionDetailsButton from 'web/src/modules/promotions/components/PromotionButton/PromotionButton.vue';
import PromotionDetailsHeader from 'web/src/modules/promotions/components/PromotionDetailsHeader/PromotionDetailsHeader.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import LeaderBoard from 'web/src/modules/promotions/components/LeaderBoard/LeaderBoard.vue';
import { usePromotionDetailsRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionDetailsRoutePage',
    setup (__props) {
        const { isReady, currentPromotion, currentPromoLeaderboardDate, showBadges, hasCounter, isBeforeStart, counterTimestamp, isCustomerParticipatingCashback, buttonProperties, isArchived, isDoingActionButton, contentLoaded, isShowTermsData, termsData, rounds, isLeaderBoard, leaderBoardProperties, isPromotionsLeaderboardArchiveEnabled, spoilerRecord, isShowPromoSocialLinks, isNicknameModal, modalSelector, modalProps, isNicknameFlowFinal, schema, uiSchema, customErrors, terms, cashbackAmount, isCashbackInfoModal, modalCashbackInfoProps, board, sendRequest, preDoButtonAction, scrollToLeaderBoard, doButtonAction, goToLoginPage, scrollToTerms, goToCustomLink, goToPlay, refuseCashback, loadMore, onSpoilerOpen, tournamentProperties, onClose, onLater, onInput, onSubmit, onButtonClick, beforeMount, beforeUnmount, showIdentificationNeededModal } = usePromotionDetailsRoutePage();
        onBeforeMount(beforeMount);
        onBeforeUnmount(beforeUnmount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['component-wrapper'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['promotion'])
                }, [
                    _unref(isReady) && _unref(currentPromotion) ? (_openBlock(), _createElementBlock("article", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['promotion__article'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['promotion__header-wrapper']]: true,
                                [_ctx.$style['promotion__header-wrapper--ended']]: _unref(isArchived)
                            })
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['promotion__image-wrapper'])
                            }, [
                                _createElementVNode("img", {
                                    class: _normalizeClass(_ctx.$style['promotion__image']),
                                    src: _unref(currentPromotion).bannerUrl || void 0,
                                    alt: _unref(currentPromotion).shortDescription || void 0
                                }, null, 10, _hoisted_1)
                            ], 2),
                            _unref(showBadges) ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['promotion__image-badges'])
                            }, [
                                _unref(currentPromotion).isHot ? (_openBlock(), _createBlock(_unref(VIcon), {
                                    key: 0,
                                    size: _unref(IconSize).SIZE_16,
                                    name: _unref(IconName).FIRE,
                                    class: _normalizeClass(_ctx.$style['promotion__image-hot'])
                                }, null, 8, [
                                    "size",
                                    "name",
                                    "class"
                                ])) : _createCommentVNode("", true),
                                _unref(currentPromotion).isNew ? (_openBlock(), _createBlock(VBadge, {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['promotion__image-badge']),
                                    kind: _unref(BadgeKind).WHITE,
                                    label: "New"
                                }, null, 8, [
                                    "class",
                                    "kind"
                                ])) : _createCommentVNode("", true)
                            ], 2)) : _createCommentVNode("", true),
                            _unref(currentPromotion).isNT ? (_openBlock(), _createBlock(VBadge, {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['promotion__image-badge--dev-ip']),
                                kind: _unref(BadgeKind).WHITE,
                                label: "DevIP"
                            }, null, 8, [
                                "class",
                                "kind"
                            ])) : _createCommentVNode("", true),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['promotion__rate'])
                            }, [
                                _unref(isArchived) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(PromotionRate, {
                                    key: 0
                                }))
                            ], 2)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['promotion__padded-content'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['promotion__brief-content'])
                            }, [
                                _createVNode(PromotionDetailsHeader, {
                                    class: _normalizeClass(_ctx.$style['promotion__title-wrapper']),
                                    promotion: _unref(currentPromotion)
                                }, null, 8, [
                                    "class",
                                    "promotion"
                                ]),
                                _unref(hasCounter) ? (_openBlock(), _createBlock(PromotionCountdown, {
                                    key: 0,
                                    "is-before-start": _unref(isBeforeStart),
                                    timestamp: _unref(counterTimestamp),
                                    "is-cashback": _unref(isCustomerParticipatingCashback),
                                    "is-alt-time-name": _unref(isCustomerParticipatingCashback),
                                    onSend: _unref(sendRequest)
                                }, null, 8, [
                                    "is-before-start",
                                    "timestamp",
                                    "is-cashback",
                                    "is-alt-time-name",
                                    "onSend"
                                ])) : _createCommentVNode("", true),
                                _unref(buttonProperties) && !_unref(isArchived) ? (_openBlock(), _createBlock(PromotionDetailsButton, _mergeProps({
                                    key: 1
                                }, _unref(buttonProperties), {
                                    "button-is-loading": _unref(isDoingActionButton),
                                    class: _ctx.$style['promotion__button'],
                                    onParticipate: _unref(preDoButtonAction),
                                    onLeaderboard: _unref(scrollToLeaderBoard),
                                    onExchange: _unref(doButtonAction),
                                    onLogin: _unref(goToLoginPage),
                                    onOnBonusWallet: _unref(scrollToTerms),
                                    onCustom: _unref(goToCustomLink),
                                    onPlay: _unref(goToPlay),
                                    onIdentificationNeeded: _unref(showIdentificationNeededModal)
                                }), null, 16, [
                                    "button-is-loading",
                                    "class",
                                    "onParticipate",
                                    "onLeaderboard",
                                    "onExchange",
                                    "onLogin",
                                    "onOnBonusWallet",
                                    "onCustom",
                                    "onPlay",
                                    "onIdentificationNeeded"
                                ])) : _createCommentVNode("", true),
                                _unref(isCustomerParticipatingCashback) ? (_openBlock(), _createBlock(VButton, {
                                    key: 2,
                                    label: _ctx.$t('WEB2_CASHBACK_BUTTON_REFUSE_LABEL'),
                                    kind: _unref(ButtonKind).SECONDARY,
                                    class: _normalizeClass(_ctx.$style['promotion__button-refuse']),
                                    onClick: _unref(refuseCashback)
                                }, null, 8, [
                                    "label",
                                    "kind",
                                    "class",
                                    "onClick"
                                ])) : _createCommentVNode("", true),
                                _createVNode(PromotionDetailsRefuseButton, {
                                    class: _normalizeClass(_ctx.$style['promotion__refuse'])
                                }, null, 8, [
                                    "class"
                                ])
                            ], 2),
                            _unref(contentLoaded) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                                key: 0
                            })),
                            _unref(isShowTermsData) && _unref(termsData)?.sportBonus ? (_openBlock(), _createBlock(PromotionDetailsTerms, _mergeProps({
                                key: 1
                            }, _unref(termsData), {
                                ref_key: "terms",
                                ref: terms,
                                class: _ctx.$style['promotion__term-steps']
                            }), null, 16, [
                                "class"
                            ])) : _createCommentVNode("", true),
                            _createVNode(_unref(PromotionDetailsHowDoesItWork), {
                                class: _normalizeClass(_ctx.$style['promotion__how-does-it-work']),
                                title: _unref(currentPromotion).howWorksTitle || '',
                                items: _unref(currentPromotion).howWorksItems || []
                            }, null, 8, [
                                "class",
                                "title",
                                "items"
                            ]),
                            _createVNode(_unref(PromotionDetailsHowToReceiveBonus), {
                                class: _normalizeClass(_ctx.$style['promotion__how-to-receive-bonus']),
                                title: _unref(currentPromotion).bonusTitle || '',
                                items: _unref(currentPromotion).bonusItems || []
                            }, null, 8, [
                                "class",
                                "title",
                                "items"
                            ])
                        ], 2),
                        _unref(isCustomerParticipatingCashback) && !!_unref(rounds) ? (_openBlock(), _createBlock(PromotionCashbackHistory, {
                            key: 0,
                            list: _unref(rounds),
                            "current-cashback": _unref(cashbackAmount)
                        }, null, 8, [
                            "list",
                            "current-cashback"
                        ])) : _createCommentVNode("", true),
                        _unref(isLeaderBoard) ? (_openBlock(), _createBlock(LeaderBoardWrapper, {
                            key: 1,
                            ref_key: "board",
                            ref: board,
                            subheader: _unref(currentPromoLeaderboardDate)
                        }, {
                            default: _withCtx(()=>[
                                    _createVNode(LeaderBoard, _mergeProps(_unref(leaderBoardProperties), {
                                        class: "promotion__leader-board promotion__leader-board--default",
                                        onLoadMore: _unref(loadMore)
                                    }), null, 16, [
                                        "onLoadMore"
                                    ]),
                                    _unref(isPromotionsLeaderboardArchiveEnabled) ? (_openBlock(true), _createElementBlock(_Fragment, {
                                        key: 0
                                    }, _renderList(_unref(currentPromotion).tournaments, (tournament)=>(_openBlock(), _createBlock(LeaderBoardSpoiler, {
                                            key: tournament.id,
                                            class: _normalizeClass({
                                                [_ctx.$style['promotion__leader-board']]: true,
                                                [_ctx.$style['promotion__leader-board--spoiler']]: true
                                            }),
                                            opened: !!_unref(spoilerRecord)[tournament.id],
                                            title: tournament.name,
                                            onOpen: ($event)=>_unref(onSpoilerOpen)(tournament.id)
                                        }, {
                                            default: _withCtx(()=>[
                                                    _createVNode(LeaderBoard, _mergeProps({
                                                        ref_for: true
                                                    }, _unref(tournamentProperties)(tournament.id).value, {
                                                        onLoadMore: ($event)=>_unref(loadMore)(tournament.id)
                                                    }), null, 16, [
                                                        "onLoadMore"
                                                    ])
                                                ]),
                                            _: 2
                                        }, 1032, [
                                            "class",
                                            "opened",
                                            "title",
                                            "onOpen"
                                        ]))), 128)) : _createCommentVNode("", true)
                                ]),
                            _: 1
                        }, 8, [
                            "subheader"
                        ])) : _createCommentVNode("", true),
                        _unref(isShowPromoSocialLinks) ? (_openBlock(), _createBlock(PromotionDetailsSocials, {
                            key: 2,
                            class: _normalizeClass(_ctx.$style['promotion__socials']),
                            promotion: _unref(currentPromotion)
                        }, null, 8, [
                            "class",
                            "promotion"
                        ])) : _createCommentVNode("", true)
                    ], 2)) : _createCommentVNode("", true),
                    _unref(isReady) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['loader-masked'])
                    }, [
                        _createVNode(_unref(VLoaderDelayed))
                    ], 2))
                ], 2),
                _unref(isNicknameModal) ? (_openBlock(), _createBlock(_Teleport, {
                    key: 0,
                    to: _unref(modalSelector)
                }, [
                    _createVNode(ConfirmModal, _mergeProps(_unref(modalProps), {
                        "is-alert": true,
                        onClose: _unref(onClose),
                        onButtonClick: _unref(onLater)
                    }), {
                        default: _withCtx(()=>[
                                _unref(isNicknameFlowFinal) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(VForm), {
                                    key: 0,
                                    schema: _unref(schema),
                                    "ui-schema": _unref(uiSchema),
                                    "custom-errors": _unref(customErrors),
                                    "is-from-modal": "",
                                    class: _normalizeClass(_ctx.$style['modal__form']),
                                    onInput: _unref(onInput),
                                    onSubmit: _unref(onSubmit)
                                }, null, 8, [
                                    "schema",
                                    "ui-schema",
                                    "custom-errors",
                                    "class",
                                    "onInput",
                                    "onSubmit"
                                ]))
                            ]),
                        _: 1
                    }, 16, [
                        "is-alert",
                        "onClose",
                        "onButtonClick"
                    ])
                ], 8, [
                    "to"
                ])) : _createCommentVNode("", true),
                _unref(isCashbackInfoModal) ? (_openBlock(), _createBlock(_Teleport, {
                    key: 1,
                    to: _unref(modalSelector)
                }, [
                    _createVNode(ConfirmModal, {
                        modal: _unref(modalCashbackInfoProps),
                        "is-alert": true,
                        onClose: _unref(onClose),
                        onButtonClick: _unref(onButtonClick)
                    }, null, 8, [
                        "modal",
                        "is-alert",
                        "onClose",
                        "onButtonClick"
                    ])
                ], 8, [
                    "to"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PromotionDetailsRoutePage'
                ]
            ]);
        };
    }
});
