import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Teleport as _Teleport, Fragment as _Fragment } from "vue";
import { onBeforeMount } from 'vue';
import PromotionRateButton from 'web/src/modules/promotions/components/PromotionRateButton/PromotionRateButton.vue';
import LikeFavorite from 'web/src/components/LikeFavorite/LikeFavorite/LikeFavorite.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import { ModalWidth } from 'web/src/components/Modal/enums';
import VFeedbackComposition from 'web/src/components/FeedbackComposition/VFeedbackComposition/VFeedbackComposition.vue';
import { usePromotionRate } from './composables';
import DefaultModal from '../../../../components/Modal/DefaultModal/DefaultModal.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionRate',
    setup (__props) {
        const { isPromotionsLikeEnabled, isRateFeedBackVisible, isLikePressed, isDislikePressed, isRateButtonEnabled, isRateFeedBackModal, modalSelector, promotionLikes, feedbackCountdown, feedbackFormMaxChars, submitting, haveValidEmailValue, isApiErrorEmailMessage, savedErrorDuplicateEmail, beforeMount, handleLike, handleDislike, feedbackRate, onRateFeedbackSubmit, onCloseRateFeedback } = usePromotionRate();
        onBeforeMount(beforeMount);
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                _unref(isPromotionsLikeEnabled) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['promotion-rate'])
                }, [
                    _unref(isRateFeedBackVisible) && _unref(feedbackCountdown) > 0 ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['promotion-rate__button-feedback'])
                    }, [
                        (_openBlock(), _createBlock(LButton, {
                            key: 1,
                            label: `${_ctx.$t('WEB2_RATE_PROMOTION')} (${String(_unref(feedbackCountdown))})`,
                            height: _unref(ButtonHeight).TINY,
                            kind: _unref(ButtonKind).MONOCHROME,
                            "is-uppercase": false,
                            class: _normalizeClass(_ctx.$style['promotion__rate-button-feedback']),
                            onClick: _unref(feedbackRate)
                        }, null, 8, [
                            "label",
                            "height",
                            "kind",
                            "class",
                            "onClick"
                        ]))
                    ], 2)) : _createCommentVNode("", true),
                    (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['promotion-rate__buttons'])
                    }, [
                        _createVNode(PromotionRateButton, {
                            "is-like-button": "",
                            likes: _unref(promotionLikes),
                            "is-pressed": _unref(isLikePressed),
                            disabled: !_unref(isRateButtonEnabled),
                            onClick: _unref(handleLike)
                        }, null, 8, [
                            "likes",
                            "is-pressed",
                            "disabled",
                            "onClick"
                        ]),
                        _createVNode(PromotionRateButton, {
                            "is-pressed": _unref(isDislikePressed),
                            disabled: !_unref(isRateButtonEnabled),
                            onClick: _unref(handleDislike)
                        }, null, 8, [
                            "is-pressed",
                            "disabled",
                            "onClick"
                        ])
                    ], 2))
                ], 2)) : _createCommentVNode("", true),
                _unref(isRateFeedBackModal) ? (_openBlock(), _createBlock(_Teleport, {
                    key: 1,
                    to: _unref(modalSelector)
                }, [
                    _createVNode(DefaultModal, {
                        "is-alert": true,
                        width: _unref(ModalWidth).SMALL,
                        "top-bar-text": _ctx.$t('WEB2_RATE_PROMOTION_FEEDBACK'),
                        buttons: [],
                        "is-centered": false,
                        "is-full-height": false,
                        "is-full-height-centered": false,
                        onClose: _unref(onCloseRateFeedback)
                    }, {
                        content: _withCtx(()=>[
                                _createVNode(VFeedbackComposition, {
                                    "feedback-form-max-chars": _unref(feedbackFormMaxChars),
                                    "have-valid-email-value": _unref(haveValidEmailValue),
                                    submitting: _unref(submitting),
                                    "is-api-error-email-message": _unref(isApiErrorEmailMessage),
                                    "saved-error-duplicate-email": _unref(savedErrorDuplicateEmail),
                                    onSubmit: _unref(onRateFeedbackSubmit)
                                }, {
                                    default: _withCtx(()=>[
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('WEB2_RATE_PROMOTION_FEEDBACK_DESCRIPTION_1')), 1),
                                            _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('WEB2_RATE_PROMOTION_FEEDBACK_DESCRIPTION_2')), 1)
                                        ]),
                                    _: 1
                                }, 8, [
                                    "feedback-form-max-chars",
                                    "have-valid-email-value",
                                    "submitting",
                                    "is-api-error-email-message",
                                    "saved-error-duplicate-email",
                                    "onSubmit"
                                ])
                            ]),
                        _: 1
                    }, 8, [
                        "is-alert",
                        "width",
                        "top-bar-text",
                        "is-centered",
                        "is-full-height",
                        "onClose"
                    ])
                ], 8, [
                    "to"
                ])) : _createCommentVNode("", true)
            ], 64));
    }
});
