import RouteName from '@leon-hub/routing-config-names';
export default function resolvePromotionLink(item, router) {
    const options = {
        params: {
            categoryId: String(item.props?.categoryId),
            actionUrl: item.id
        }
    };
    return router.resolve({
        name: RouteName.PROMOTION_DETAILS,
        ...options
    });
}
