import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import PromotionCountdown from 'web/src/modules/promotions/components/PromotionCountdown/PromotionCountdown.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import usePromotionsListItem from 'web/src/modules/promotions/components/PromotionsListItem/composables/usePromotionsListItem';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LPromotionsListItem',
    props: {
        promotionId: {},
        isArchived: {
            type: Boolean
        },
        isPromotionsLikeEnabled: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { metaParameters, showBadges, hasCounter, imageProps, detailsLocation, textPosition, likes, timestamp, promotion, isBeforeStart, isCustomerParticipatingCashback, sendRequest } = usePromotionsListItem(props);
        useSeoMetaParameters(metaParameters);
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                class: _normalizeClass(_ctx.$style['promotions__link']),
                to: _unref(detailsLocation)
            }, {
                default: _withCtx(()=>[
                        _unref(promotion) ? (_openBlock(), _createElementBlock("article", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['promotion-item'])
                        }, [
                            _unref(promotion).position ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['promotion-item__position'])
                            }, [
                                _createVNode(_unref(VIcon), {
                                    class: _normalizeClass(_ctx.$style['promotion-item__position-icon']),
                                    name: _unref(IconName).CUP,
                                    size: _unref(IconSize).SIZE_16
                                }, null, 8, [
                                    "class",
                                    "name",
                                    "size"
                                ]),
                                _createTextVNode(" " + _toDisplayString(_unref(promotion).position) + _toDisplayString(_unref(textPosition)), 1)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'promotion-item-position'
                                    }
                                ]
                            ]) : _createCommentVNode("", true),
                            _createElementVNode("figure", {
                                class: _normalizeClass(_ctx.$style['promotion-item__image-wrapper'])
                            }, [
                                _unref(promotion).shortBannerImage ? _withDirectives((_openBlock(), _createBlock(VImage, _mergeProps({
                                    key: 0
                                }, _unref(imageProps), {
                                    class: {
                                        [_ctx.$style['promotion-item__image']]: true,
                                        [_ctx.$style['promotion-item__image--archived']]: _ctx.isArchived
                                    }
                                }), null, 16, [
                                    "class"
                                ])), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'short-banner-image'
                                        }
                                    ]
                                ]) : _withDirectives((_openBlock(), _createElementBlock("img", {
                                    key: 1,
                                    class: _normalizeClass({
                                        [_ctx.$style['promotion-item__image']]: true,
                                        [_ctx.$style['promotion-item__image--archived']]: _ctx.isArchived
                                    }),
                                    src: _unref(promotion).shortBannerUrl ?? void 0,
                                    alt: _unref(promotion).shortDescription ?? void 0
                                }, null, 10, _hoisted_1)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'banner-image'
                                        }
                                    ]
                                ]),
                                _unref(hasCounter) ? (_openBlock(), _createBlock(PromotionCountdown, {
                                    key: 2,
                                    class: _normalizeClass(_ctx.$style['promotion-item__countdown']),
                                    "is-before-start": _unref(isBeforeStart),
                                    timestamp: _unref(timestamp),
                                    "is-cashback": _unref(isCustomerParticipatingCashback),
                                    "is-alt-time-name": _unref(isCustomerParticipatingCashback),
                                    onSend: _unref(sendRequest)
                                }, null, 8, [
                                    "class",
                                    "is-before-start",
                                    "timestamp",
                                    "is-cashback",
                                    "is-alt-time-name",
                                    "onSend"
                                ])) : _createCommentVNode("", true),
                                _ctx.isPromotionsLikeEnabled && _unref(likes) && _unref(likes) > 0 ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                    key: 3,
                                    class: _normalizeClass(_ctx.$style['promotion-item__likes'])
                                }, [
                                    _createVNode(_unref(VIcon), {
                                        name: _unref(IconName).LIKE,
                                        size: _unref(IconSize).SIZE_16,
                                        class: _normalizeClass(_ctx.$style['promotion-item__likes-icon'])
                                    }, null, 8, [
                                        "name",
                                        "size",
                                        "class"
                                    ]),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['promotion-item__likes-value'])
                                    }, _toDisplayString(_unref(likes)), 3)
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'promotion-item-likes'
                                        }
                                    ]
                                ]) : _createCommentVNode("", true)
                            ], 2),
                            _unref(showBadges) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['promotion-item__image-badges'])
                            }, [
                                _unref(promotion).isHot ? _withDirectives((_openBlock(), _createBlock(_unref(VIcon), {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['promotion-item__image-hot']),
                                    size: _unref(IconSize).SIZE_16,
                                    name: _unref(IconName).FIRE
                                }, null, 8, [
                                    "class",
                                    "size",
                                    "name"
                                ])), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'promotion-badges-hot'
                                        }
                                    ]
                                ]) : _createCommentVNode("", true),
                                _unref(promotion).isNew ? _withDirectives((_openBlock(), _createBlock(VBadge, {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['promotion-item__image-badge']),
                                    kind: _unref(BadgeKind).WHITE,
                                    label: "New"
                                }, null, 8, [
                                    "class",
                                    "kind"
                                ])), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'promotion-badges-isnew'
                                        }
                                    ]
                                ]) : _createCommentVNode("", true)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'promotion-badges'
                                    }
                                ]
                            ]) : _createCommentVNode("", true),
                            _unref(promotion).isNT ? _withDirectives((_openBlock(), _createBlock(VBadge, {
                                key: 2,
                                class: _normalizeClass(_ctx.$style['promotion-item__image-badge--dev-ip']),
                                kind: _unref(BadgeKind).WHITE,
                                label: "DevIP"
                            }, null, 8, [
                                "class",
                                "kind"
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'promotion-badges-isnt'
                                    }
                                ]
                            ]) : _createCommentVNode("", true),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['promotion-item__description'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['promotion-item__title-wrapper'])
                                }, [
                                    _createElementVNode("p", {
                                        class: _normalizeClass(_ctx.$style['promotion-item__title'])
                                    }, _toDisplayString(_unref(promotion).name), 3)
                                ], 2),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['promotion-item__short-text'])
                                }, _toDisplayString(_unref(promotion).shortDescription), 3)
                            ], 2)
                        ], 2)) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "class",
                "to"
            ])), [
                [
                    _directive_auto_id,
                    'PromotionsListItem'
                ]
            ]);
        };
    }
});
