import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "terms-step-progress"
};
const _hoisted_2 = {
    class: "terms-step-progress__footer"
};
const _hoisted_3 = {
    class: "terms-step-progress__percent"
};
const _hoisted_4 = {
    class: "terms-step-progress__current"
};
const _hoisted_5 = {
    class: "terms-step-progress__total"
};
import { RequirementValueType } from '@leon-hub/api-sdk';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import { ProgressKind } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import { useVTermsStepProgress } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VTermsStepProgress',
    props: {
        progressBarValue: {
            default: 0
        },
        valueType: {
            default: RequirementValueType.COUNT
        },
        kind: {
            default: ProgressKind.DEFAULT
        },
        currentValue: {
            default: '0'
        },
        requiredValue: {
            default: '0'
        }
    },
    setup (__props) {
        const props = __props;
        const { progressProperties, total } = useVTermsStepProgress(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(VLinearProgress, _mergeProps({
                    class: "terms-step-progress__progress"
                }, _unref(progressProperties)), null, 16),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.progressBarValue) + " % ", 1),
                    _createElementVNode("div", null, [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.currentValue), 1),
                        _cache[0] || (_cache[0] = _createElementVNode("span", {
                            class: "terms-step-progress__separator"
                        }, "/", -1)),
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(total)), 1)
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'VTermsStepProgress'
                ]
            ]);
        };
    }
});
