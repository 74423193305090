import { computed } from 'vue';
import { IconName, IconSize, IconDirection } from '@leon-hub/icons';
export default function useLeaderBoardSpoiler() {
    const indicatorProperties = computed(()=>[
            {
                direction: IconDirection.SOUTH,
                size: IconSize.SIZE_16,
                name: IconName.CHEVRON
            },
            {
                direction: IconDirection.NORTH,
                size: IconSize.SIZE_16,
                name: IconName.CHEVRON
            }
        ]);
    return {
        indicatorProperties
    };
}
