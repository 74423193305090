import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import PromotionsListItem from 'web/src/modules/promotions/components/PromotionsListItem/PromotionsListItem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionsList',
    props: {
        promotions: {},
        isArchived: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['promotions__list'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promotions, (promotion)=>(_openBlock(), _createBlock(PromotionsListItem, {
                        key: promotion,
                        "promotion-id": promotion,
                        "is-archived": _ctx.isArchived,
                        "is-promotions-like-enabled": false,
                        class: _normalizeClass(_ctx.$style['promotions__list-item'])
                    }, null, 8, [
                        "promotion-id",
                        "is-archived",
                        "class"
                    ]))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'PromotionsList'
                ]
            ]);
        };
    }
});
