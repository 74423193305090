import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue";
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import usePromotionRefuseButton from 'web/src/modules/promotions/components/PromotionRefuseButton/composables/usePromotionRefuseButton';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionsRefuseButton',
    setup (__props) {
        const { isRefuseButtonAvailable, refuseButtonProperties, refuseButtonLoading, onRefuseClick } = usePromotionRefuseButton();
        return (_ctx, _cache)=>_unref(isRefuseButtonAvailable) ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
            }, [
                (_openBlock(), _createBlock(LButton, _mergeProps({
                    key: 1
                }, _unref(refuseButtonProperties), {
                    class: _ctx.$style['promotion__refuse-btn'],
                    onClick: _unref(onRefuseClick)
                }), null, 16, [
                    "class",
                    "onClick"
                ])),
                _createCommentVNode("", true)
            ], 64)) : _createCommentVNode("", true);
    }
});
